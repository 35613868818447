import { getCookie, setCookie } from '@pangaea-holdings/pangaea-checkout'

import { COOKIE_POLICY_NAME } from './constants'

export const getCookieBannerStatus = () => {
  let decodedCookieBannerStatus = 'status'
  try {
    const win = window as Window
    const cookieBannerStatus = getCookie(COOKIE_POLICY_NAME)
    decodedCookieBannerStatus = JSON.parse(win.atob(cookieBannerStatus)).status
  } catch (error) {
    // swallow
  }
  return decodedCookieBannerStatus
}

export const saveCookieBannerStatus = () => {
  try {
    const win = window as Window
    const cookieBannerStatus = getCookie(COOKIE_POLICY_NAME)
    const decodedCookieBannerStatus = JSON.parse(win.atob(cookieBannerStatus))
    const newStatus = {
      ...decodedCookieBannerStatus,
      status: 'allow',
      timestamp: Math.round(new Date().getTime() / 1000),
      preferences: 0,
    }
    setCookie(COOKIE_POLICY_NAME, win.btoa(JSON.stringify(newStatus)), 365)
  } catch (error) {
    // swallow
  }
}
