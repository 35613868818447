import { TFunction } from 'i18next'

import {
  DEFAULT_INFLUENCERS_URL,
  INFLUENCER_HANDLES,
} from './constants/influencersList'
import { Influencer } from './types'

const buildUrl = (
  influencer: string,
  utmSource = 'youtube',
  promoCode = ''
) => {
  const promoCodeString = promoCode ? `&promocode=${promoCode}` : ''
  return `/pages/free-trial-4?_referral_coupon=FREETRIAL5&in=${influencer}&utm_source=${utmSource}&utm_medium=influencer&utm_campaign=${influencer}${promoCodeString}`
}

export const getInfluencerByHandle = (
  influencerHandle: string,
  t: TFunction
): Influencer => {
  const getBanner = (handle: string) => {
    return t(
      'influencers:banner-v1',
      '@{{handle}} welcomes you to Lumin. Your exclusive free trial offer will be applied at checkout.',
      { handle }
    )
  }

  const list: { [k in string]: Influencer } = {
    wulffden: {
      handle: 'WulffDen',
      banner: getBanner('WulffDen'),
      url: buildUrl('WulffDen'),
    },
    jacksfilms: {
      handle: 'Jacksfilms',
      banner: getBanner('JacksFilms'),
      url: buildUrl('JacksFilms'),
    },
    frugalaesthetic: {
      handle: 'FrugalAesthetic',
      banner: getBanner('FrugalAesthetic'),
      url: buildUrl('FrugalAesthetic'),
    },
    sufficientwhispers: {
      handle: 'SufficientWhispers',
      banner: getBanner('SufficientWhispers'),
      url: buildUrl('SufficientWhispers'),
    },
    tyturner: {
      handle: 'TyTurner',
      banner: getBanner('TyTurner'),
      url: buildUrl('TyTurner'),
    },
    ricermiata: {
      handle: 'RicerMiata',
      banner: getBanner('RicerMiata'),
      url: buildUrl('RicerMiata'),
    },
    clrbrucerivers: {
      handle: 'CLRBruceRivers',
      banner: getBanner('CLRBruceRivers'),
      url: buildUrl('CLRBruceRivers'),
    },
    redpoppyranch: {
      handle: 'RedPoppyRanch',
      banner: getBanner('RedPoppyRanch'),
      url: buildUrl('RedPoppyRanch'),
    },
    vehiclevirgins: {
      handle: 'VehicleVirgins',
      banner: getBanner('VehicleVirgins'),
      url: buildUrl('VehicleVirgins'),
    },
    harrisonnevel: {
      handle: 'HarrisonNevel',
      banner: getBanner('HarrisonNevel'),
      url: buildUrl('HarrisonNevel'),
    },
    seanandsierra: {
      handle: 'SeanandSierra',
      banner: getBanner('SeanandSierra'),
      url: buildUrl('SeanandSierra'),
    },
    thequeensfamily: {
      handle: 'Thequeensfamily',
      banner: getBanner('Thequeensfamily'),
      url: buildUrl('Thequeensfamily'),
    },
    anaandalex: {
      handle: 'ANAandALEX',
      banner: getBanner('ANAandALEX'),
      url: buildUrl('ANAandALEX'),
    },
    lingandlamb: {
      handle: 'LingandLamb',
      banner: getBanner('LingandLamb'),
      url: buildUrl('LingandLamb'),
    },
    chrisandemily: {
      handle: 'ChrisandEmily',
      banner: getBanner('ChrisandEmily'),
      url: buildUrl('ChrisandEmily'),
    },
    farinajo: {
      handle: 'FarinaJo',
      banner: getBanner('FarinaJo'),
      url: buildUrl('FarinaJo'),
    },
    okbaby: {
      handle: 'OKbaby',
      banner: getBanner('OKbaby'),
      url: buildUrl('OKbaby'),
    },
    becomeelite: {
      handle: 'becomeelite',
      banner: getBanner('becomeelite'),
      url: buildUrl('becomeelite'),
    },
    yaboylsull: {
      handle: 'yaboylsull',
      banner: getBanner('yaboylsull'),
      url: buildUrl('yaboylsull', 'tiktok'),
    },
    simpletonyy: {
      handle: 'simpletonyy',
      banner: getBanner('simpletonyy'),
      url: buildUrl('simpletonyy', 'tiktok'),
    },
    lifewithnt3: {
      handle: 'lifewithnt3',
      banner: getBanner('lifewithnt3'),
      url: buildUrl('lifewithnt3', 'tiktok'),
    },
    jaydnchoi: {
      handle: 'jaydnchoi',
      banner: getBanner('jaydnchoi'),
      url: buildUrl('jaydnchoi', 'tiktok', 'JAYDNCHOI'),
    },
    matthewtaylorzu: {
      handle: 'matthewtaylorzu',
      banner: getBanner('matthewtaylorzu'),
      url: buildUrl('matthewtaylorzu', 'tiktok'),
    },
    logintologan: {
      handle: 'logintologan',
      banner: getBanner('logintologan'),
      url: buildUrl('logintologan', 'tiktok', 'LOGINTOLOGAN'),
    },
    wdonovan98: {
      handle: 'wdonovan98',
      banner: getBanner('wdonovan98'),
      url: buildUrl('wdonovan98', 'tiktok', 'WDONOVAN98'),
    },
    mrdanielnguyen: {
      handle: 'mrdanielnguyen',
      banner: getBanner('mrdanielnguyen'),
      url: buildUrl('mrdanielnguyen', 'instagram', 'MRDANIELNGUYEN'),
    },
    frickinalexnguyen: {
      handle: 'frickinalexnguyen',
      banner: getBanner('frickinalexnguyen'),
      url: buildUrl('frickinalexnguyen', 'tiktok', 'FRICKINALEXNGUYEN'),
    },
    air4sonee: {
      handle: 'air4sonee',
      banner: getBanner('air4sonee'),
      url: buildUrl('air4sonee', 'tiktok', 'AIR4SONEE'),
    },
    patrickreynolds: {
      handle: 'patrickreynolds',
      banner: getBanner('patrickreynolds'),
      url: buildUrl('patrickreynolds', 'tiktok', 'PATRICKREYNOLDS'),
    },
    scottbrodie1: {
      handle: 'scottbrodie1',
      banner: getBanner('scottbrodie1'),
      url: buildUrl('scottbrodie1', 'tiktok', 'SCOTTBRODIE1'),
    },
    alexandrotiberiu: {
      handle: 'alexandrotiberiu',
      banner: getBanner('alexandrotiberiu'),
      url: buildUrl('alexandrotiberiu', 'tiktok', 'ALEXANDROTIBERIU'),
    },
    isaacsmithfits: {
      handle: 'isaacsmithfits',
      banner: getBanner('isaacsmithfits'),
      url: buildUrl('isaacsmithfits', 'tiktok', 'ISAACSMITHFITS'),
    },
    hangingwithmrjones: {
      handle: 'hangingwithmrjones',
      banner: getBanner('hangingwithmrjones'),
      url: buildUrl('hangingwithmrjones', 'tiktok', 'HANGINGWITHMRJONES'),
    },
    rickygiaovu: {
      handle: 'rickygiaovu',
      banner: getBanner('rickygiaovu'),
      url: buildUrl('rickygiaovu', 'tiktok', 'RICKYGIAOVU'),
    },
    corneliusmasterson: {
      handle: 'corneliusmasterson',
      banner: getBanner('corneliusmasterson'),
      url: buildUrl('corneliusmasterson', 'tiktok', 'CORNELIUSMASTERSON'),
    },
    okiridinfresh: {
      handle: 'okiridinfresh',
      banner: getBanner('okiridinfresh'),
      url: buildUrl('okiridinfresh', 'tiktok', 'OKIRIDINFRESH'),
    },
    aminnafarfit: {
      handle: 'aminnafarfit',
      banner: getBanner('aminnafarfit'),
      url: buildUrl('aminnafarfit', 'tiktok', 'AMINNAFARFIT'),
    },
    gerardiperformance: {
      handle: 'gerardiperformance',
      banner: getBanner('gerardiperformance'),
      url: buildUrl('gerardiperformance', 'tiktok', 'GERARDIPERFORMANCE'),
    },
    braxtonmarvell: {
      handle: 'braxtonmarvell',
      banner: getBanner('braxtonmarvell'),
      url: buildUrl('braxtonmarvell', 'tiktok', 'BRAXTONMARVELL'),
    },
    ethanepstein: {
      handle: 'ethanepstein',
      banner: getBanner('ethanepstein'),
      url: buildUrl('ethanepstein', 'tiktok', 'ETHANEPSTEIN'),
    },
    wufitness: {
      handle: 'wufitness',
      banner: getBanner('wufitness'),
      url: buildUrl('wufitness', 'tiktok', 'WUFITNESS'),
    },
    ewenward: {
      handle: 'ewenward',
      banner: getBanner('ewenward'),
      url: buildUrl('ewenward', 'tiktok', 'EWENWARD'),
    },
    williamguerra: {
      handle: 'williamguerra',
      banner: getBanner('williamguerra'),
      url: buildUrl('williamguerra', 'tiktok', 'WILLIAMGUERRA'),
    },
    donjaeperformance: {
      handle: 'donjaeperformance',
      banner: getBanner('donjaeperformance'),
      url: buildUrl('donjaeperformance', 'tiktok', 'DONJAEPERFORMANCE'),
    },
    vinnydonnini: {
      handle: 'vinnydonnini',
      banner: getBanner('vinnydonnini'),
      url: buildUrl('vinnydonnini', 'tiktok', 'VINNYDONNINI'),
    },
    zbreezyy: {
      handle: 'zbreezyy',
      banner: getBanner('zbreezyy'),
      url: buildUrl('zbreezyy', 'tiktok', 'ZBREEZYY'),
    },
    mylzinthecut: {
      handle: 'mylzinthecut',
      banner: getBanner('mylzinthecut'),
      url: buildUrl('mylzinthecut', 'tiktok', 'MYLZINTHECUT'),
    },
  }

  return list[influencerHandle?.toLowerCase()]
}

export const getInfluencerUrl = (handle: string) => {
  if (INFLUENCER_HANDLES.includes(handle)) {
    const influencer = getInfluencerByHandle(handle, () => '')
    return influencer.url
  }
  return DEFAULT_INFLUENCERS_URL
}
