import { Text } from '@chakra-ui/core'
import { useAmountExchangeRateValue } from '@pangaea-holdings/pangaea-checkout'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const FatherDaySaleBanner: React.FC = () => {
  const { t } = useTranslation()
  const { formattedAmount } = useAmountExchangeRateValue(5000)
  const amount = formattedAmount.replace('.00', '')

  return (
    <Trans i18nKey={'announcement-bar:father-day-sale-banner'} t={t}>
      <Text
        textAlign="center"
        className="announcement-text"
        fontSize="sm"
        lineHeight="1.5em"
      >
        Help Dad get healthier skin this year. Get a FREE SPF when you spend{' '}
        {{ amount }}
        &nbsp;or more. Code:&nbsp;
        <Text
          as="span"
          className="announcement-text"
          fontSize="sm"
          lineHeight="1.5em"
          fontWeight="bold"
        >
          GIFT4DAD
        </Text>
      </Text>
    </Trans>
  )
}
export default FatherDaySaleBanner
