import { noop } from '@pangaea-holdings/pangaea-checkout'
import { TFunction } from 'i18next'

import { getTrialConfig } from '../constants/freeTrialConstants'
import { FreeTrialCatalog } from '../types'

export const isFreeTrialPage = (page: string) => {
  for (const code in FreeTrialCatalog) {
    if (FreeTrialCatalog[code] === page) return true
  }
  return false
}

export const isDeprecatedFreeTrialPage = (page: string) => {
  const fakeTFunction: TFunction = noop
  const config = getTrialConfig(fakeTFunction, page)
  return config?.redirectToBaseTrialPage
}
