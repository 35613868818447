import {
  CheckoutContext,
  getUserCountryCode,
} from '@pangaea-holdings/pangaea-checkout'
import * as Sentry from '@sentry/browser'
import { useCallback, useContext, useEffect, useState } from 'react'

export function useCurrentCountryCode() {
  const { state } = useContext(CheckoutContext)
  const country = state.countries.selectedCountryId

  const [currentCountryCode, setCurrentCountryCode] = useState<string | null>(
    country
  )

  const fetchCountryCode = useCallback(async () => {
    try {
      const countryCode = await getUserCountryCode()
      setCurrentCountryCode(countryCode)
    } catch (e) {
      Sentry.captureException('Failed to fetch countryCode ', e)
    }
  }, [])

  useEffect(() => {
    if (country) {
      setCurrentCountryCode(country)
      return
    }

    fetchCountryCode()
  }, [country])

  return currentCountryCode
}
