import { COOKIE_LANG_KEY } from '@pangaea-holdings/pangaea-auth'
import { getCookie } from '@pangaea-holdings/pangaea-checkout'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'


export type Dir = 'rtl' | 'ltr'

export const useRTL = () => {
  const [dir, setDir] = useState<Dir>('ltr')
  const [isRTL, setIsRTL] = useState(false)
  const { i18n } = useTranslation()

  useEffect(() => {
    const newDir = i18n.dir()
    setIsRTL(newDir === 'rtl')
    setDir(newDir)
  }, [])

  return { isRTL, dir }
}

export const useLanguage = () => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => {
    const lang = getCookie(COOKIE_LANG_KEY)
    if (lang) {
      setLanguage(lang)
      i18n.changeLanguage(lang)
    }
  }, [])

  return { language }
}

export const useChangeLanguage = () => {
  const { i18n } = useTranslation()

  return (lang) => {
    i18n.changeLanguage(lang)
    // URL is not supported in op_mini all
    // eslint-disable-next-line compat/compat
    const params = new URL(window.location.toString()).searchParams
    params.set(COOKIE_LANG_KEY, lang)
    window.location.assign(`${window.location.pathname}?${params.toString()}`)
  }
}
