import { Flex, Box, Text } from '@chakra-ui/core'
import React from 'react'

import { LuminRebrandLogo } from '../Icons/LuminRebrandLogo'

import ShopifyNextLink from 'modules/shared/components/ShopifyNextLink'

const ShopifyFooter = ({ pageType }) => {
  return (
    <>
      <Box bgColor="black" width="100%" py={6} px={12} color="white">
        <Flex
          direction={['column', 'row']}
          justify="space-between"
          align="center"
        >
          <Flex mb={[6, 0]} direction={['column', 'row']} align="center">
            <LuminRebrandLogo
              color="white"
              w="160px"
              h="30px"
              px="2"
              margin={0}
            />
            <Text size="md">© Copyright 2023 Lumin®. All Rights Reserved.</Text>
          </Flex>
          <Flex>
            <ShopifyNextLink
              pl={8}
              textDecoration="underline"
              href="https://www.luminskin.com/policies/privacy-policy"
            >
              Privacy policy
            </ShopifyNextLink>
            <ShopifyNextLink
              pl={8}
              textDecoration="underline"
              href="https://www.luminskin.com/policies/terms-of-service"
            >
              Terms of use
            </ShopifyNextLink>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default ShopifyFooter
