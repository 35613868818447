import {
  getCookie,
  getUserCountryCode,
  Product,
  productsApi,
  SHIPPING_COUNTRY_COOKIE,
} from '@pangaea-holdings/pangaea-checkout'
import * as Sentry from '@sentry/browser'

import { ThunkActionCreator } from '../../redux/rootReducer'
import { AppActionCreator } from '../../redux/store'
import { DEFAULT_COUNTRY_CODE } from '../shared/constants/general'
import { fetchProduct, isSubDiscountKeySame } from './functions'
import {
  selectAllProducts,
  selectCatalogProducts,
  selectMultipleProducts,
  selectProductsSubDiscountKeys,
} from './selectors'
import { ProductRenewalDiscountRule } from './types'

import { featureFlag } from 'modules/optimizely/hooks/useFeatureFlag'

export const fetchAllProductsCached: ThunkActionCreator<Promise<Product[]>> =
  (
    currency: string,
    countryCode?: string,
    subscriptionDiscountKeys?: string[]
  ) =>
  async (dispatch, getState) => {
    const renewalDiscountRule: ProductRenewalDiscountRule = featureFlag(
      'RENEWAL_DISCOUNT_RULE',
      undefined
    )
    subscriptionDiscountKeys =
      !subscriptionDiscountKeys && renewalDiscountRule
        ? [renewalDiscountRule.subscriptionDiscountKey]
        : subscriptionDiscountKeys
    const products = selectAllProducts(currency, countryCode)(getState())
    const stateSubDiscountKeys = selectProductsSubDiscountKeys()(getState())

    if (
      products &&
      isSubDiscountKeySame(subscriptionDiscountKeys, stateSubDiscountKeys)
    ) {
      return products
    }

    const fetchedProducts = await productsApi.getAllProducts(
      currency,
      undefined,
      countryCode,
      subscriptionDiscountKeys
    )

    dispatch({
      type: 'PRODUCTS_LOAD_ALL' as const,
      payload: {
        products: fetchedProducts,
        currency,
        countryCode,
        subDiscountKeys: subscriptionDiscountKeys,
      },
    })
    return fetchedProducts
  }

export const fetchCatalogProductsCached: ThunkActionCreator<
  Promise<Product[]>
> = (currency?: string) => async (dispatch) => {
  let countryCode

  try {
    countryCode =
      getCookie(SHIPPING_COUNTRY_COOKIE) ||
      (await getUserCountryCode()) ||
      DEFAULT_COUNTRY_CODE
  } catch (e) {
    Sentry.captureException('Failed to fetch countryCode ', e)
  }

  const products = await dispatch(fetchAllProductsCached(currency, countryCode))

  return products
}

export const fetchMultipleProductsCached: ThunkActionCreator<
  Promise<Product[]>
> =
  (
    currency: string,
    productIds: number[],
    subscriptionDiscountKeys?: string[]
  ) =>
  async (dispatch, getState) => {
    const renewalDiscountRule: ProductRenewalDiscountRule = featureFlag(
      'RENEWAL_DISCOUNT_RULE',
      undefined
    )
    subscriptionDiscountKeys =
      !subscriptionDiscountKeys && renewalDiscountRule
        ? [renewalDiscountRule.subscriptionDiscountKey]
        : subscriptionDiscountKeys
    const countryCode = (await getUserCountryCode()) || DEFAULT_COUNTRY_CODE
    const products = selectMultipleProducts(
      currency,
      productIds,
      countryCode
    )(getState())
    const stateSubDiscountKeys = selectProductsSubDiscountKeys()(getState())

    if (
      products &&
      isSubDiscountKeySame(subscriptionDiscountKeys, stateSubDiscountKeys)
    ) {
      console.log(`cache hit on multiple products ${currency}`)
      return products
    }

    console.log(`cache miss on multiple products ${currency}`)

    const fetchedProducts = await productsApi.getAllProducts(
      currency,
      productIds,
      undefined,
      subscriptionDiscountKeys
    )

    dispatch({
      type: 'PRODUCTS_LOAD_MULTIPLE' as const,
      payload: {
        products: fetchedProducts,
        currency,
      },
    })
    // always return in right order
    return productIds.map((id) => {
      return fetchedProducts.find((product) => product.id === id) as Product
    })
  }

type ProductWithCountryAvailability = Product & {
  isAvailableInCountry: boolean
}
export const fetchProductCached: ThunkActionCreator<
  Promise<ProductWithCountryAvailability | null>
> =
  (
    slugOrId: string | number,
    currency: string,
    subscriptionDiscountKeys?: string[]
  ) =>
  async (dispatch, getState) => {
    const countryCode = (await getUserCountryCode()) || DEFAULT_COUNTRY_CODE

    let product: Product | null | undefined = fetchProduct(
      slugOrId,
      currency,
      getState,
      countryCode
    )

    const renewalDiscountRule: ProductRenewalDiscountRule = featureFlag(
      'RENEWAL_DISCOUNT_RULE',
      undefined
    )

    subscriptionDiscountKeys =
      !subscriptionDiscountKeys && renewalDiscountRule
        ? [renewalDiscountRule.subscriptionDiscountKey]
        : subscriptionDiscountKeys
    const subDiscountKeys = selectProductsSubDiscountKeys()(getState())

    if (
      !product &&
      isSubDiscountKeySame(subscriptionDiscountKeys, subDiscountKeys)
    ) {
      await dispatch(
        fetchAllProductsCached(currency, countryCode, subscriptionDiscountKeys)
      )
    } else {
      await dispatch(fetchAllProductsCached(currency, countryCode))
    }

    product = fetchProduct(slugOrId, currency, getState, countryCode)

    if (product != null) {
      return { ...product, isAvailableInCountry: true }
    }

    const fetchedProduct = await productsApi.getSingleProduct(
      slugOrId,
      currency,
      undefined,
      subscriptionDiscountKeys
    )
    dispatch(
      loadSingleProduct(currency, fetchedProduct, subscriptionDiscountKeys)
    )

    return { ...(fetchedProduct || {}), isAvailableInCountry: false }
  }

export const loadSingleProduct: AppActionCreator = (
  currency: string,
  product: Product,
  subDiscountKeys?: string[]
) => ({
  type: 'PRODUCTS_LOAD_SINGLE' as const,
  payload: {
    product,
    currency,
    subDiscountKeys,
  },
})

export const fetchProductInCatalogCached: ThunkActionCreator<
  Promise<Product[]>
> = (currency: string, countryCode: string) => async (dispatch, getState) => {
  const catalogKey = `${countryCode.toLowerCase()}:${currency.toLowerCase()}`
  const cachedProducts = selectCatalogProducts(catalogKey)(getState())

  if (cachedProducts) {
    return cachedProducts
  }

  const fetchedProducts = await productsApi.getAllProducts(
    currency,
    undefined,
    countryCode
  )

  dispatch({
    type: 'PRODUCTS_LOAD_CATALOG' as const,
    payload: {
      catalogKey,
      products: fetchedProducts,
    },
  })
  return fetchedProducts
}
