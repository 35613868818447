import { noop } from '@pangaea-holdings/pangaea-checkout'
import { useState, useEffect } from 'react'

import { useTimeLeft } from './useTimeLeft'

export const useTimerCountDown = (
  duration: number,
  onEnd = noop,
  loop = false
) => {
  const timer = useTimeLeft(duration * 60, onEnd, loop)
  const [minutes, setMinutes] = useState(`${duration}`)
  const [seconds, setSeconds] = useState('00')

  useEffect(() => {
    const minutesLeft = Math.floor(timer / 60)
    const secondsLeft = Math.floor(timer % 60)

    setMinutes(minutesLeft < 10 ? `0${minutesLeft}` : `${minutesLeft}`)
    setSeconds(secondsLeft < 10 ? `0${secondsLeft}` : `${secondsLeft}`)
  }, [timer])

  return { minutes, seconds }
}
