import { Link as ChakraLink, LinkProps } from '@chakra-ui/core'
import { noop } from '@pangaea-holdings/pangaea-checkout'
import Link from 'next/link'
import React from 'react'

import config from '../../../core/config'

type ShopifyNextLinkProps = Omit<LinkProps, 'as'> & {
  href: string
  as?: string
  label?: string
  shopifyHref?: string
  isInternal?: boolean
  onClick?: () => void
}

const ShopifyNextLink: React.FC<ShopifyNextLinkProps> = ({
  href,
  isInternal = true,
  onClick = noop,
  shopifyHref,
  as,
  label,
  children,
  ...rest
}) => {
  if (isInternal) {
    return (
      <Link aria-label={label} href={href} as={as} passHref prefetch={false}>
        <ChakraLink onClick={onClick} {...rest}>
          {children}
        </ChakraLink>
      </Link>
    )
  }

  let finalHref: string
  const pathToUrl = shopifyHref ?? as ?? href
  if (/^(http|mailto)/.test(pathToUrl)) {
    finalHref = pathToUrl
  } else {
    finalHref = `${config('SHOPIFY_APP_BASEURL')}${pathToUrl}`
  }

  return (
    <ChakraLink aria-label={label} href={finalHref} onClick={onClick} {...rest}>
      {children}
    </ChakraLink>
  )
}

export default ShopifyNextLink
