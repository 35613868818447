import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const LuminRebrandLogo = createIcon({
  displayName: 'LuminRebrandLogo',
  viewBox: '0 0 201 34',
  path: (
    <>
      <path
        d="M197.047 21.5626C194.586 23.8024 192.736 25.9063 192.756 29.4349C192.766 31.6481 190.509 33.2418 188.335 33.1258H188.309C186.138 33.2418 183.878 31.6481 183.888 29.4349C183.908 25.9063 182.058 23.8024 179.597 21.5626C173.994 16.4702 174.405 7.64366 180.449 2.76987C182.729 0.931022 185.524 0.00662649 188.322 0C191.12 0.00662649 193.915 0.934336 196.196 2.76987C202.239 7.64366 202.647 16.4702 197.047 21.5626Z"
        fill="#FCDC00"
      />
      <path d="M125.261 0H120.045V32.6289H125.261V0Z" fill="currentColor" />
      <path
        d="M19.8306 23.4147C17.156 25.8864 14.9155 27.8743 13.0857 29.3984C11.2559 30.9259 10.2141 31.615 9.56636 31.615H5.39589V0H0V32.6289H18.8823L25.2465 20.4361H23.1529C22.0711 21.3804 20.9191 22.4108 19.8306 23.4147Z"
        fill="currentColor"
      />
      <path
        d="M57.5286 17.2289C57.5286 22.2319 56.3532 25.787 53.8356 28.3183C51.3146 30.8496 48.4865 32.0292 44.9204 32.0292C41.8451 32.0292 39.7081 30.7734 37.9818 27.9638C36.2589 25.1575 35.4008 21.6819 35.3073 17.2289V0H29.2603V17.2289C29.2603 21.5593 30.8196 25.4457 33.7713 28.4641C36.723 31.4825 40.5796 33.0993 44.9137 33.1292C49.0908 33.159 52.6302 31.5752 55.0443 28.6C57.4585 25.628 58.7306 21.7117 58.7306 17.2256V0H57.5252V17.2289H57.5286Z"
        fill="currentColor"
      />
      <path
        d="M88.4412 22.7918L72.2302 0H63.769L67.9462 4.85059V32.6289H69.1482V6.26535L88.0606 32.6289H95.8205L89.1858 23.8123L104.145 2.39879L104.188 32.6289H109.678V0H104.332L88.4412 22.7918Z"
        fill="currentColor"
      />
      <path
        d="M164.264 0L164.397 27.3707L140.169 0H130.353L136.737 5.69547V32.5792H137.939V7.02077L159.676 32.6289H165.513V0H164.264Z"
        fill="currentColor"
      />
    </>
  ),
})
