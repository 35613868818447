import { FreeTrialProduct } from '@pangaea-holdings/pangaea-checkout'

import {
  AVAILABLE_TRIAL_OFFERS,
  AVAILABLE_TRIAL_PRICES,
} from './constants/availableOffer'

export enum FreeTrialCatalog {
  FREE_TRIAL = 'free-trial',
  FREE_TRIAL_3 = 'free-trial-3',
  FREE_TRIAL_10 = 'free-trial-10',
  FREE_TRIAL_4 = 'free-trial-4',
  FREE_TRIAL_5 = 'free-trial-5',
  FREE_TRIAL_8 = 'free-trial-8',
  FREE_TRIAL_6 = 'free-trial-6',
  FREE_TRIAL_9 = 'free-trial-9',
  FREE_TRIAL_7 = 'free-trial-7',
  FREE_TRIAL_11 = 'free-trial-11',
  FREE_TRIAL_12 = 'free-trial-12',
  FREE_TRIAL_UV_4 = 'free-trial-uv-4',
  FREE_TRIAL_UV_11 = 'free-trial-uv-11',
  FREE_TRIAL_HELLO_FRESH = 'free-trial-hello-fresh',
  OFFERS_SUBSCRIPTIONS_1 = 'offers-subscriptions-1',
  // OFFERS_SUBSCRIPTIONS_2 = 'offers-subscriptions-2', disabled this page temporarily
  MODERN_BATHROOM = 'modern-bathroom',
  MODERN_BATHROOM_7 = 'modern-bathroom-7',
  MODERN_BATHROOM_4 = 'modern-bathroom-4',
  MODERN_BATHROOM_2 = 'modern-bathroom-2',
  MODERN_BATHROOM_5 = 'modern-bathroom-5',
  MODERN_BATHROOM_3 = 'modern-bathroom-3',
  MODERN_BATHROOM_6 = 'modern-bathroom-6',
  FREE_TRIAL_LSETS = 'free-trial-lsets',
  FREE_TRIAL_LSETS_4 = 'free-trial-lsets-4',
  FREE_TRIAL_LSETS_2 = 'free-trial-lsets-2',
  FREE_TRIAL_LSETS_3 = 'free-trial-lsets-3',
  FREE_TRIAL_LSETS_5 = 'free-trial-lsets-5',
  FREE_TRIAL_LSETS_UV_2 = 'free-trial-lsets-uv-2',
  FREE_TRIAL_ANTI_FATIGUE = 'free-trial-antifatigue',
  UP_TOP = 'up-top',
  // OFFERS_GIFT_HAIRCARE_1 = 'offers-gift-haircare-1',
  // OFFERS_GIFT_HAIRCARE_2 = 'offers-gift-haircare-2',
  // OFFERS_GIFT_FACEMASK_RECOVERY_OIL_1 = 'offers-gift-facemask-recovery-oil-1',
  // OFFERS_GIFT_FACEMASK_RECOVERY_OIL_2 = 'offers-gift-facemask-recovery-oil-2',  disabled this page temporarily
  FREE_TRIAL_CLASSIC_MAINTENANCE_SET = 'free-trial-classic-maintenance-set',
  FREE_TRIAL_AGE_MANAGEMENT_SET = 'free-trial-age-management-set',
  FREE_TRIAL_ANTI_FATIGUE_ESSENTIAL_SET = 'free-trial-anti-fatigue-essentials-set',
  FREE_TRIAL_QUARTERLY_FUNDAMENTAL_DUO = 'free-trial-quarterly-fundamental-duo',
  FREE_TRIAL_FUNDAMENTAL_DUO_SET_WTP = 'free-trial-fundamental-duo-set-wtp',
  FREE_TRIAL_FUNDAMENTAL_DUO_SET_WTP_WMT_DRY = 'free-trial-fundamental-duo-set-wtp-wmt-dry',
  FREE_TRIAL_FUNDAMENTAL_DUO_SET_WTP_WMT_OILY_NORMAL = 'free-trial-fundamental-duo-set-wtp-wmt-oily-normal',
  FREE_TRIAL_NO_BAGGAGE_TRIO = 'free-trial-no-baggage-trio',
}

export interface ImageDetails {
  maxHeight: string[]
  blogUrl?: string
  src: any
  name: string
}

export interface CustomerReview {
  name: string
  header: string
  text: string
  fontWeight?: string
}

export interface CustomerTestimonial {
  name: string
  age: string
  skinType: 'Combination' | 'Oily'
  productUse: string
  image: string
  comment: string
}

// This represents client side data for free trial copy
// and display data.  It is to be joined with the free trial
// by linking the productSlug
export type FreeTrialExtraProductData = {
  productSlug: string
  desc: string
  helpsWith: string[]
  heroImage: string
  kitIncludes: string[]
  kitIncludesDesc?: string
  helpsWithDesc: string
  concerns?: string[]
}

// Configuration done at the component level of what free
// trial offers should be shown.
export interface FreeTrialConfig {
  // slug of the free trial
  id: string
  trendingNow?: boolean
  shortSupply?: boolean
  order?: number[]
  imageSet?: string[]
  recommended?: boolean
  recurringMonthlyInterval?: number
}

// Joining of config data, product data and server side fetched
// free trial offer data.  This type should hvae all the data we
// need to display free trials
export type FreeTrialData = FreeTrialConfig &
  FreeTrialProduct &
  FreeTrialExtraProductData

export interface TextProps {
  text: string
  isBold?: boolean
  noSpace?: boolean
}

export type SkinConcernsId =
  | 'dark_circles'
  | 'sun_damage'
  | 'wrinkles'
  | 'oily_skin'
  | 'looking_tired'
  | 'acne_scars'
  | 'uneven_tone'
  | 'skin_health'
  | 'dark_circles,sun_damage'
  | 'dark_circles,wrinkles'
  | 'dark_circles,oily_skin'
  | 'dark_circles,looking_tired'
  | 'dark_circles,acne_scars'
  | 'dark_circles,uneven_tone'
  | 'sun_damage,wrinkles'
  | 'sun_damage,oily_skin'
  | 'sun_damage,looking_tired'
  | 'sun_damage,acne_scars'
  | 'sun_damage,uneven_tone'
  | 'wrinkles,oily_skin'
  | 'wrinkles,looking_tired'
  | 'wrinkles,acne_scars'
  | 'wrinkles,uneven_tone'
  | 'oily_skin,looking_tired'
  | 'oily_skin,acne_scars'
  | 'oily_skin,uneven_tone'
  | 'looking_tired,acne_scars'
  | 'looking_tired,uneven_tone'
  | 'acne_scars,uneven_tone'
  | 'skin_health,dark_circles'
  | 'skin_health,sun_damage'
  | 'skin_health,wrinkles'
  | 'skin_health,oily_skin'
  | 'skin_health,looking_tired'
  | 'skin_health,acne_scars'
  | 'skin_health,uneven_tone'

export type FreeTrialSet =
  | 'correction-trio-1'
  | 'age-management-1'
  | 'classic-maintanence-1'

export type ImageSet = {
  name: string
  imagePath: string
  hasHealthAward?: boolean
}

export type ProductDetail = {
  header: string
  productSolutions: string[]
}

export type ProductPreview = {
  title: string
  bgColor: string
  description: string
  imageSet: ImageSet[]
  productDetail: ProductDetail
}

export type GetForFreeDetail = {
  title: string
  description: string
  imageSet: string
}

export type HeroImage = {
  deskTop: string
  mobile: string
}

export type ContentVariant = {
  heroTitle?: string
  heroSubtitle?: string
  heroImage?: HeroImage
  topNavImage?: string
}

export type RenewalDiscountConfigVariants =
  | 'first10'
  | 'firstThree10'
  | 'first20'
  | 'firstThree20'

export type RenewalDiscount = {
  discount: number
  numberOfRenewal: number
  offer: string
}

export type RenewalDiscountConfig = {
  renewalDiscount: RenewalDiscount
}

export type TrialProductFilterOption = {
  title: string
  value: number
}

export interface TrialOfferDescription {
  prices: {
    [P in AvailableTrialPrices]?: string
  }
  imageSet: string[]
}

export type AvailableTrialPrices = typeof AVAILABLE_TRIAL_PRICES[number]

export type AvailableTrialOffers = keyof typeof AVAILABLE_TRIAL_OFFERS

export interface TrialOfferDisplayConfig {
  offer: AvailableTrialOffers
  trending?: boolean
  order?: number[]
  price?: AvailableTrialPrices
  recommended?: boolean
}

export interface TrialConfig {
  offers: (AvailableTrialOffers | TrialOfferDisplayConfig)[]
  defaultPrice: AvailableTrialPrices
  title: string
  templateVariant?: 'freeTrial' | 'modernBathroom'
  contentVariant?: ContentVariant
  hasNoFreeContent?: boolean
  heroTitleText?: string
  hasSubscribeEmailPopup?: boolean
  offerExperimentName?: string
  offerDisplayName?: string
  offerType?: 'discount' | 'freeGift'
  coupon?: string
  preCheckoutMessage?: string
  discountRate?: number
  cardDetails?: { title: string; details: string }
  gift?: { title: string; imageUrl: string }
  enableRenewalDiscount?: boolean
  renewalDiscount?: RenewalDiscount
  deprecated?: boolean
  isNoFreeTrial?: boolean
  redirectToBaseTrialPage?: boolean
  recurringMonthlyInterval?: number
  initialInterval?: number
}
