import { NextPage } from 'next'
import React, { ReactElement } from 'react'

import usePageType from '../../analytics/hooks/usePageType'
import { ANALYTICS_PAGE_TYPES } from '../../analytics/types'
import { LightLayoutProps } from '../components/Layout/LightLayout'
import { MainLayoutProps } from '../components/Layout/MainLayout'

import { GetLayoutOptions } from 'modules/shared/types'

type WithLayoutOptions = (LightLayoutProps | MainLayoutProps) & {
  pageType: ANALYTICS_PAGE_TYPES | null
}

function withLayout<T>(
  Component: NextPage<T>,
  Layout: React.FC,
  options: WithLayoutOptions
) {
  const { pageType } = options

  const WithLayoutComponent = (props: T) => {
    usePageType(pageType)

    return <Component {...props} />
  }

  WithLayoutComponent.getLayout = function getLayout(
    page: ReactElement,
    layoutOptions?: GetLayoutOptions
  ) {
    let layoutProps = options
    if (!options.store) {
      layoutProps = { ...options, store: layoutOptions?.store }
    }
    return <Layout {...layoutProps}>{page}</Layout>
  }

  if ('getInitialProps' in Component) {
    console.log('has initial props')
    WithLayoutComponent.getInitialProps = Component.getInitialProps
  }

  return WithLayoutComponent
}

export default withLayout
