import { useState, useEffect, Dispatch } from 'react'

import { OptimizelyFlagWindow } from '../types'

const flags = {}
const overrides = {}
const subscribers = {}

if (process.browser) {
  const win = window as OptimizelyFlagWindow

  if (win.optimizelyFeatureFlags && Array.isArray(win.optimizelyFeatureFlags)) {
    win.optimizelyFeatureFlags.forEach((entry) => {
      flags[entry.key] = entry.value
    })
  }
  win.optimizelyFeatureFlags = {
    push(entry: { key: string; value: any }) {
      flags[entry.key] = entry.value
      notifySubscribers(entry.key)
    },
  }
}

/**
 * Subscribes to anytime a feature flag is set or overridden,
 * regardless of it actually changing value
 * @param {string} flag
 * @param {(value: any) => void} onChange
 * @return {() => void} unsusbcribe fn
 */
function subscribe(flag: string, onChange: Dispatch<any>) {
  subscribers[flag] = subscribers[flag] || []
  subscribers[flag].push(onChange)

  return () => {
    if (!subscribers[flag]) {
      return
    }
    const ind = subscribers[flag].indexOf(onChange)
    if (ind > -1) {
      subscribers[flag].splice(ind, 1)
    }
  }
}

function notifySubscribers(flag: string) {
  const onChangeFns = subscribers[flag]
  if (onChangeFns && Array.isArray(onChangeFns)) {
    onChangeFns.forEach((fn) => fn(featureFlag(flag)))
  }
}

/**
 * React hook for subscribing to a feature flag
 *
 * @param {string} flag
 * @param {any} defaultValue
 */
export function useFeatureFlag(flag: string, defaultValue: any = false) {
  const [value, setValue] = useState(featureFlag(flag, defaultValue))

  useEffect(() => {
    return subscribe(flag, setValue)
  }, [flag])

  return value
}

export function allFeatureFlags() {
  return {
    ...flags,
    ...overrides,
  }
}

export function overrideFlag(key: string, value: any) {
  overrides[key] = value
  notifySubscribers(key)
}

export function featureFlag(flagKey: string, defaultValue: any = false) {
  const override = overrides[flagKey]
  if (override !== undefined) {
    return override
  }

  const val = flags[flagKey]
  if (val === undefined) {
    return defaultValue
  }
  return val
}

// expose functions for testing
if (process.browser) {
  window['allFeatureFlags'] = allFeatureFlags
  window['overrideFlag'] = overrideFlag
}
