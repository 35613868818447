import { Product, VatConfig } from '@pangaea-holdings/pangaea-checkout'

import { useCurrentCountryDetails } from '../lumin/hooks/shippingCountries'
import { useFeatureFlag } from '../optimizely/hooks/useFeatureFlag'
import {
  getOneTimePurchasePrice,
  getProductPrice,
  getPromoPrice,
  getSubDiscountRulePrice,
} from '../products/functions'
import {
  ProductRenewalDiscountRule,
  ProductWithDetails,
} from '../products/types'

export function useVatConfig(): VatConfig {
  const country = useCurrentCountryDetails()

  return {
    enabled: !!country?.isVat,
    showVatIncludedLabel: false,
  }
}

export function useGetOneTimePurchasePrice(
  product: Product,
  sizeOptionId?: number
): number {
  const currentCountry = useCurrentCountryDetails()
  const { enabled } = useVatConfig()

  const price = enabled
    ? getOneTimePurchasePrice(product, sizeOptionId, currentCountry)
    : getOneTimePurchasePrice(product, sizeOptionId)

  return price || 0
}

export function useGetSubDiscountRulePrice(
  product: Product,
  sizeOptionId?: number
): number {
  const renewalDiscountRule: ProductRenewalDiscountRule = useFeatureFlag(
    'RENEWAL_DISCOUNT_RULE'
  )
  const currentCountry = useCurrentCountryDetails()
  const { enabled } = useVatConfig()

  const price = enabled
    ? getSubDiscountRulePrice(
        product,
        renewalDiscountRule.subscriptionDiscountKey,
        sizeOptionId,
        currentCountry
      )
    : getSubDiscountRulePrice(
        product,
        renewalDiscountRule.subscriptionDiscountKey,
        sizeOptionId
      )

  return price || 0
}

export function useGetProductPrice(product: Product, quantity = 1) {
  const currentCountry = useCurrentCountryDetails()
  const { enabled } = useVatConfig()

  const price = enabled
    ? getProductPrice(product, quantity, currentCountry)
    : getProductPrice(product, quantity)

  return price || 0
}

export function useGetPromoPrice() {
  const currentCountry = useCurrentCountryDetails()
  const { enabled } = useVatConfig()

  return (product: ProductWithDetails, sizeOptionId?: number) =>
    getPromoPrice(product, sizeOptionId, enabled ? currentCountry : undefined)
}
