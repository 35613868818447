import { NextRouter } from 'next/router'

export const removeQueryParam = (
  queryParamName: string,
  router: NextRouter,
  shallow?: boolean
) => {
  if (typeof window === 'object') {
    const params = new URLSearchParams(window.location.search)

    params.delete(queryParamName)
    const paramString = params.toString()
    const newUrl = `${window.location.pathname}${
      paramString ? '?' : ''
    }${paramString}`
    if (shallow) {
      router.replace(newUrl, undefined, { shallow: true })
    } else {
      router.replace(newUrl)
    }
  }
}
