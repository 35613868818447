import { Box, Flex, Link, Text } from '@chakra-ui/core'
import { useRTL } from '@pangaea-holdings/pangaea-checkout'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ShopifyNextLink from '../ShopifyNextLink'

import { GeoIPLocation } from 'modules/shared/types'
import { COOKIE_POLICY_EXCLUSION_LIST } from 'utils/constants'
import {
  getCookieBannerStatus,
  saveCookieBannerStatus,
} from 'utils/getCookieBannerStatus'

const CookiePolicyBanner = () => {
  const { t } = useTranslation()
  const { isRTL } = useRTL()
  const [showCookiePolicy, setShowCookiePolicy] = useState(false)
  const GE0O_IP_URL =
    'https://geoip-js.com/geoip/v2.1/city/me?referrer=https%3A%2F%2Fcheckout.luminskin.com'

  useEffect(() => {
    ;;(async function maybShowBanner() {
      let isCityExcluded = false
      if (getCookieBannerStatus() !== 'allow') {
        try {
          const res = await fetch(GE0O_IP_URL)
          const cityData = (await res.json()) as GeoIPLocation
          const city = cityData?.subdivisions?.at(0)?.iso_code || ''
          const country = cityData?.country.iso_code || ''
          isCityExcluded = COOKIE_POLICY_EXCLUSION_LIST[country]?.includes(city)
        } catch (error) {
          console.log(error)
          // swallow
        }
        setShowCookiePolicy(!isCityExcluded)
      }
    })()
  }, [])

  const acceptCookie = () => {
    saveCookieBannerStatus()
    setShowCookiePolicy(false)
  }

  return (
    <Box mb={showCookiePolicy ? ['9rem', '4rem'] : []}>
      {showCookiePolicy && (
        <Flex
          direction={['column', 'row']}
          background="primaryBgColor"
          justify="center"
          alignItems="center"
          position="fixed"
          bottom="0"
          width="100%"
          boxShadow="addressBlockShadow"
          zIndex="sticky"
          px={['0.5rem', '3rem']}
          py="4"
        >
          <Text size="sm" lineHeight={1.4}>
            {t(
              'common:accept-message-terms',
              'By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation and assist in our marketing efforts.'
            )}
            &nbsp;
            <ShopifyNextLink
              href="/policies/privacy-policy#cookie-and-similar-technologies"
              textDecoration="underline"
              display="inline-block"
              target="_blank"
              mr={isRTL ? 0 : 2}
              ml={isRTL ? 2 : 0}
            >
              {t('common:learn-more', 'Learn more')}
            </ShopifyNextLink>
          </Text>
          <Link
            background="black"
            flexShrink={0}
            color="white"
            px="4"
            py="2"
            ml="2"
            mr="2"
            display="inline-block"
            fontSize="0.7rem"
            textAlign="center"
            fontWeight="bold"
            onClick={acceptCookie}
            alignSelf="center"
            minW={['100%', '10rem']}
            mt={['1rem', '0']}
          >
            {t('common:accept-cookies', 'Accept All Cookies')}
          </Link>
        </Flex>
      )}
    </Box>
  )
}

export default CookiePolicyBanner
