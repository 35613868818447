import { Text } from '@chakra-ui/core';
import { AfterpayClearpayMessageLogo } from '@pangaea-holdings/pangaea-checkout';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '../../../optimizely/hooks/useFeatureFlag'

export const AfterpayClearpayBar: React.FC = () => {
  const { t } = useTranslation()
  const showAfterpayDayBanner = useFeatureFlag(
    'SHOW_AFTERPAY_DAY_BANNER',
    false
  )

  if (showAfterpayDayBanner) {
    return (
      <Text
        as="div"
        px={2}
        mb="-4px"
        css={{
          '.AfterpayMessage-logo::before': {
            content: `"${t(
              'afterpay:us-customers-buy-now-pay-later',
              'US customers can Buy Now, Pay Later with'
            )}"`,
            marginRight: '5px',
          },
          '.AfterpayMessage-logo::after': {
            content: `"${t(
              'afterpay:get-25-percent-off-afterpayday',
              'Get 25% off with code AFTERPAYDAY'
            )}"`,
            marginLeft: '5px',
          },
        }}
      >
        {/* // Using fixed values of price and currency because we are hiding the text */}
        <AfterpayClearpayMessageLogo
          logoSize={80}
          lockupTheme="mint"
          price={100}
          currency="USD"
        />
      </Text>
    )
  }

  return (
    <Text
      as="div"
      px={2}
      mb="-4px"
      css={{
        '.AfterpayMessage-logo::before': {
          content: `"${t(
            'afterpay:afterpay-is-now-available',
            'Afterpay is now available at checkout! Shop now and pay later with'
          )}"`,
          marginRight: '5px',
        },
      }}
    >
      {/* // Using fixed values of price and currency because we are hiding the text */}
      <AfterpayClearpayMessageLogo
        logoSize={80}
        lockupTheme="mint"
        price={100}
        currency="USD"
      />
    </Text>
  )
}