import { TFunction } from 'i18next'

import { TranslatableString } from '../api/types'
import {
  ApiFoundInProducts,
  ApiIngredientInfo,
  FoundInProducts,
  IngredientInfo,
} from '../ingredients/ingredientTypes'
import {
  ApiProductAllIngredients,
  ProductAllIngredients,
} from './constants/productIngredients'
import {
  ApiProductDetails,
  ApiProductIngredient,
  ApiProductUse,
  ProductDetails,
  ProductIngredient,
  ProductUse,
} from './types'

export const hydrateScalar = (
  t: TFunction,
  obj: TranslatableString | undefined
): string => {
  if (!obj) {
    return ''
  }
  return t(obj.key, obj.text, obj.options)
}

export const hydrateFoundIn = (
  t: TFunction,
  p: ApiFoundInProducts
): FoundInProducts => {
  return {
    productKey: p.productKey,
    productName: hydrateScalar(t, p.productName),
    productDesc: hydrateScalar(t, p.productDesc),
    productIngredients: p.productIngredients?.map((d) =>
      hydrateSingleIngredient(t, d)
    ),
    productAllIngredients: hydrateAllProductIngredients(
      t,
      p.productAllIngredients
    ),
    productImageUrl: p.productImageUrl,
    detailsImageUrl: p.detailsImageUrl,
  }
}

export const hydrateAllProductIngredients = (
  t: TFunction,
  ingredient?: ApiProductAllIngredients
): ProductAllIngredients | undefined => {
  if (!ingredient) {
    return undefined
  }

  const { name, description } = ingredient

  return {
    name: hydrateScalar(t, name),
    description: hydrateScalar(t, description),
  }
}

export const hydrateSingleIngredient = (
  t: TFunction,
  ingredient: ApiProductIngredient
): ProductIngredient => {
  const { name, description, imageUrl } = ingredient
  return {
    name: hydrateScalar(t, name!),
    description: hydrateScalar(t, description),
    imageUrl,
  }
}

export const hydrateSingleIngredientInfo = (
  t: TFunction,
  info: ApiIngredientInfo
): IngredientInfo => {
  return {
    name: hydrateScalar(t, info?.name),
    desc: hydrateScalar(t, info?.desc),
    helpsWith: info?.helpsWith.map((d) => hydrateScalar(t, d)),
    foundIn: info?.foundIn.map((d) => hydrateFoundIn(t, d)),
    imageUrl: info?.imageUrl,
    detailsImageUrl: info?.detailsImageUrl,
    hideInIngredientPage: info?.hideInIngredientPage,
  }
}

export const hydrateSingleUsage = (
  t: TFunction,
  item: ApiProductUse
): ProductUse => {
  const { image, description, title } = item
  return {
    title: hydrateScalar(t, title!),
    description: description?.map((d) => hydrateScalar(t, d)),
    image,
  }
}

export const hydrateSingleHelpsWith = (
  t: TFunction,
  item: Record<string, TranslatableString>
): { [k: string]: string } => {
  const res: { [k: string]: string } = {}

  for (const [k, v] of Object.entries(item)) {
    res[k] = hydrateScalar(t, v)
  }

  return res
}

export function hydrateSingleProductDetail(
  t: TFunction,
  detail: ApiProductDetails
): ProductDetails {
  return {
    id: detail.id,
    isVisibleInAccountSystem: detail.isVisibleInAccountSystem,
    category: detail.category,
    concerns: detail.concerns,
    plpImageUrl: detail.plpImageUrl,
    recommendedProductIds: detail.recommendedProductIds,
    allIngredients: detail.allIngredients?.map((d) =>
      hydrateSingleIngredient(t, d)
    ),
    headline: hydrateScalar(t, detail.headline),
    intro: hydrateScalar(t, detail.intro!),
    description: hydrateScalar(t, detail.description),
    helpsWith: detail.helpsWith?.map((d) => hydrateSingleUsage(t, d)),
    ingredients: detail.ingredients?.map((d) => hydrateSingleIngredient(t, d)),
    otherImageUrls: detail.otherImageUrls,
    usageImageUrl: detail.usageImageUrl,
    usage: detail.usage?.map((d) => hydrateSingleUsage(t, d)),
    setsWithProduct: detail.setsWithProduct,
    accountSystemAssets: {
      desc: hydrateScalar(t, detail?.accountSystemAssets?.desc),
      imageUrl: detail.accountSystemAssets?.imageUrl as string,
    },
    skinType: hydrateScalar(t, detail.skinType!),
    benefits: detail.benefits?.map((d) => ({
      name: hydrateScalar(t, d.name),
      icon: d.icon,
    })),
    sizePack: hydrateScalar(t, detail.sizePack),
    ingredientsInfo: detail.ingredientsInfo?.map((d) =>
      hydrateSingleIngredientInfo(t, d)
    ),
    disclaimerSizeOptions: detail.disclaimerSizeOptions,
    promo: detail.promo,
    isOtpOnly: detail.isOtpOnly,
    isHidden: detail.isHidden,
    badge_url: detail.badge_url,
    clearancePromoInfo: detail.clearancePromoInfo,
    isHiddenInAccountSystem: detail.isHiddenInAccountSystem,
    defaultSizeStrategy: detail.defaultSizeStrategy,
    importantInfo: hydrateScalar(t, detail.importantInfo),
  }
}

export const hydrateIngredients = (
  t: TFunction,
  info: Record<string, ApiProductIngredient>
): Record<string, ProductIngredient> => {
  const res: Record<string, ProductIngredient> = {}

  for (const [k, v] of Object.entries(info)) {
    res[k] = {
      name: hydrateScalar(t, v.name!),
      description: hydrateScalar(t, v.description),
      imageUrl: v.imageUrl,
    }
  }
  return res
}

export const hydrateIngredientsMap = (
  t: TFunction,
  info: Record<string, ApiIngredientInfo>
): Record<string, IngredientInfo> => {
  const res: Record<string, IngredientInfo> = {}

  for (const [k, v] of Object.entries(info)) {
    res[k] = hydrateSingleIngredientInfo(t, v)
  }
  return res
}
