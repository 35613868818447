import buildRoutes, { stringifyQuery } from '../shared/buildRoutes'

const staticRoutes = buildRoutes('pages', {
  // Sample routes
  // TODO: move these somewhere else
  /* Simplest case using a single placeholder */
  // single: routeFromUrl<{ id: number }>('single/[id]'),

  /* Route with multiple placeholder */
  // multiple: routeFromUrl<{ id: number; size: string }>('multiple/[id]/[size]'),

  /* For more complex requirements, you can specify your own function. The
   * parameter is always optional. If it's not passed, the function should
   * return the route with placeholders. */
  // manualFunction: (item?: { id: number }) => (
  //   item ? `details/${item.id}` : 'details/[id]'
  // ),

  reviews: 'customer-reviews',
  freeTrial: 'free-trial-3',
  freeTrial4: 'free-trial-4',
  support: 'support',
  termsOfService: 'terms-of-service',
  customerSupportPortal: 'customer-support-portal',
  covidFaq: 'covid19-faq',
  heroesProgramDiscount: 'heroes-program-discount',
  offersSubscriptions1: 'offers-subscriptions-1',
  offersSubscriptions2: 'offers-subscriptions-2',
  offersGiftHaircare1: 'offers-gift-haircare-1',
  offersGiftHaircare2: 'offers-gift-haircare-2',
  offersGiftFacemaskRecoveryOil1: 'offers-gift-facemask-recovery-oil-1',
  offersGiftFacemaskRecoveryOil2: 'offers-gift-facemask-recovery-oil-2',
  accounts: 'accounts',
  returnPolicy: 'refund-policy',
  privacyPolicy: 'privacy-policy',
  faq: 'faq',
  accessibility: 'accessibility',
  affiliateInfluencer: 'affiliate-influencer',
  findUs: 'find-us',
  discountedBoxQuiz: (query?: { [key: string]: any }) => {
    const path = 'discounted-box-quiz'
    const queryString = stringifyQuery(query)
    return queryString ? `${path}?${queryString}` : path
  },
})

export default staticRoutes
