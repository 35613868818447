import { TFunction } from 'i18next'

export type SortByOption = {
  value: string
  title: string
}

export function getSortByOptions(t: TFunction): SortByOption[] {
  return [
    {
      value: 'lowest-price',
      title: t('common:lowest-price', 'Lowest Price'),
    },
    {
      value: 'highest-price',
      title: t('common:highest-price', 'Highest Price'),
    },
  ]
}

export const PRODUCTS_ORDER_BY_CATEGORY= {
  'best-sellers': {
    'classic-maintenance-eye-essentials': 1,
  },
  face: {
    'charcoal-infused-cleanser': 1,
    'premium-grade-moisturizer': 2,
    'eye-serum': 3,
  },
  'face-bundles': {
    'classic-maintenance-eye-essentials': 1,
    'classic-maintenance-for-men': 2,
    'fundamental-duo': 3,
  },
  bundles: {
    'classic-maintenance-eye-essentials': 1,
    'classic-maintenance-for-men': 2,
    'fundamental-duo': 3,
  },
}
