import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { isFreeTrialPage } from '../freeTrial/functions/page'
import { getInfluencerByHandle } from './functions'
import { Influencer } from './types'

export function useInfluencer(): Influencer | null {
  const router = useRouter()
  const { t } = useTranslation()
  const [influencer, setInfluencer] = useState<Influencer | null>(null)

  const { page } = router.query
  const handle = router.query['in']

  useEffect(() => {
    if (!handle || !page) {
      setInfluencer(null)
    } else if (isFreeTrialPage(page as string)) {
      const search = getInfluencerByHandle(handle as string, t)
      setInfluencer(search)
    }
  }, [handle, page])

  return influencer
}
