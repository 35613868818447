import { TFunction } from 'i18next'

export const SEEN_ACCOUNT_SYSTEM_SURVEY = 'seen_account_system_survey'
export const LUMIN_PRESET_GIFT_VARIANT = 'freeGiftVariant13'

export const getSubscriptionSuccesfulCancellationMessage = (t: TFunction) => {
  return [
    t(
      'subscription:walmart-successful-cancellation-message-1',
      'Your membership has been canceled and you will not be charged for or shipped any future items unless you ask us to.'
    ),
    t(
      'subscription:walmart-successful-cancellation-message-2',
      'Please keep an eye out for special email offers and new product announcements or visit your local Walmart to shop in person!'
    ),
  ]
}

export const getSubscriptionCancellationConfirmationMessage = (t: TFunction) =>
  t(
    'subscription:bed-bath-and-beyond-you-cancellation-message',
    'You are about to cancel your subscription. You will still be able to re-activate your subscription whenever you want to or pick up a new grooming kit at your local Bed, Bath, & Beyond on your next visit!'
  )

export const getWalmartTargetAnnouncementHtml = (t: TFunction) =>
  t(
    'announcement-bar:shop-lumin-at-select-walmart-and-target',
    'You can now shop for Lumin at select {{linkHtml}} locations',
    {
      linkHtml:
        // anouncement bar is built to work with html from optimizely and doesn't support react component as message
        '<a style="text-decoration:underline;" href="https://www.luminskin.com/pages/find-us">Walmart and Target</a>',
    }
  )
