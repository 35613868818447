import {
  CheckoutContext,
  loadShippingCountries,
  loadCountries,
  CountryResponse,
} from '@pangaea-holdings/pangaea-checkout'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureFlag } from '../../optimizely/hooks/useFeatureFlag'
import { useCurrentCountryCode } from './useCurrentCountryCode'

export function useShippingCountries() {
  const { state, dispatch } = useContext(CheckoutContext)
  const shippingCountries = state.countries.shippingCountries

  useEffect(() => {
    dispatch(loadShippingCountries())
  }, [])

  return shippingCountries
}

export function useAllCountries() {
  const { state, dispatch } = useContext(CheckoutContext)
  const allCountries = state.countries.allCountries

  useEffect(() => {
    dispatch(loadCountries())
  }, [])

  return allCountries
}

export function useShippingCountryMessage() {
  const showShippingBanner = useFeatureFlag('SHOW_SHIPPING_BANNER', false)
  const [countryMessage, setCountryMessage] = useState<string | null>(null)
  const countryCode = useCurrentCountryCode()
  const allCountries = useAllCountries()
  const shippingCountries = useShippingCountries()

  const { t } = useTranslation()

  useEffect(() => {
    if (shippingCountries.length && showShippingBanner) {
      const isShippableCountry = shippingCountries.find(
        (country) => country === countryCode
      )
      if (isShippableCountry) {
        const countryDetails = allCountries.find(
          (country) => country.id === countryCode
        )
        if (countryDetails) {
          const message = t(
            'common:we-ship-locally-to',
            'We ship locally to {{country}}!',
            { country: countryDetails.name }
          )
          setCountryMessage(message)
        }
      }
    }
  }, [allCountries, shippingCountries, showShippingBanner, countryCode])

  return countryMessage
}

export function useCurrentCountryDetails(): CountryResponse | null {
  const countryCode = useCurrentCountryCode()
  const allCountries = useAllCountries()

  if (allCountries && allCountries.length > 0 && countryCode) {
    return allCountries.find((c) => c.id === countryCode) || null
  }
  return null
}
