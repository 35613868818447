import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  SelectProps,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  Stack,
} from '@chakra-ui/core'
import { ChevronDownIcon } from '@pangaea-holdings/pangaea-checkout'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useShippingCountrySelectState, CountrySelect } from './CountrySelect'
import LanguageSelector from './LanguageSelector'

import i18n from 'modules/i18n'
import { changeLanguage } from 'modules/i18n/actions'

export const CountryAndLanguageSelector = () => {
  const {
    countries,
    currentCountry,
    selectorCountry,
    setSelectorCountryId,
    handleChangeShippingCountry,
  } = useShippingCountrySelectState()

  const [selectedLang, setSelectedLang] = useState(i18n.language)
  const [openCountrySelectPopup, setOpenCountrySelectPopup] = useState(false)
  const { t } = useTranslation()

  const handleChangeShippingCountryAndLanguage = async () => {
    if (selectorCountry.id !== currentCountry.id) {
      await handleChangeShippingCountry()
    }
    if (selectedLang !== i18n.language) {
      changeLanguage(selectedLang)
    }
    setOpenCountrySelectPopup(false)
  }

  return (
    <>
      <Button
        onClick={() => setOpenCountrySelectPopup(true)}
        fontWeight="normal"
        height="40"
        variant="transparent"
        minW={[null, '128px']}
        px={[2, 4]}
      >
        <Box
          as="svg"
          width={['16px', '20px']}
          height={['16px', '20px']}
          viewBox="0 0 25 20"
        >
          <image xlinkHref={currentCountry.flag} width="25" height="20" />
        </Box>
        <Box fontSize={['0.85rem', '1.1rem']} ml={2} as="span">
          {currentCountry.currency_code} {currentCountry.currency_symbol}
        </Box>
        <ChevronDownIcon ml={2} w="0.7em" h="0.7em" />
      </Button>

      <Modal
        isCentered
        isOpen={openCountrySelectPopup}
        onClose={() => setOpenCountrySelectPopup(false)}
      >
        <ModalOverlay zIndex="modal">
          <ModalContent
            w="100%"
            maxWidth={['380px', '435px']}
            overflowY="scroll"
          >
            <ModalCloseButton m={[2, 4]} />
            <ModalBody minW="300px" py={12} px={4} maxHeight="100%">
              <Text textAlign="center" mb={3}>
                {t(
                  'checkout:change-shipping-country-lang',
                  'Change shipping country and language'
                )}
              </Text>
              <Stack spacing={6} direction="column">
                <CountrySelect
                  countries={countries}
                  selectedCountry={selectorCountry}
                  onChange={setSelectorCountryId}
                />
                <LanguageSelector
                  selectedLanguage={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                />
                <Button onClick={handleChangeShippingCountryAndLanguage}>
                  {t('common:shop-now', 'Shop Now')}
                </Button>
              </Stack>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}
