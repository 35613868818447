
import { Box, Flex, HStack } from '@chakra-ui/core';
import { LanguageSelect } from '@pangaea-holdings/pangaea-checkout';

import { changeLanguage } from '../../i18n/actions';
import { useLanguage, useRTL } from '../../i18n/hooks';
import { LuminRebrandLogo } from './Icons/LuminRebrandLogo';

import { SUPPORTED_LANGUAGES } from 'modules/i18n/config';
import ShopifyNextLink from 'modules/shared/components/ShopifyNextLink';

export default function ShopifyTopNav({
  hasBorder = true,
  hideTopNav = false,
}) {
  const { language } = useLanguage()
  const { isRTL } = useRTL()

  return (
    <Flex
      id="top-nav-container"
      justify={['flex-start', 'center', 'center']}
      align="center"
      py={4}
      px={4}
      borderBottomWidth={hasBorder ? '1px' : 'none'}
      borderBottomColor={hasBorder ? 'black' : 'none'}
      position="relative"
      bgColor="white"
      display={hideTopNav ? 'none' : 'flex'}
      dir={isRTL ? 'rtl' : 'ltr'}
    >
      <Box as={ShopifyNextLink} href="https://www.luminskin.com/">
        <LuminRebrandLogo w="150px" h="30px" />
      </Box>

      <Box
        position="absolute"
        zIndex="dropdown"
        right={isRTL ? 'unset' : 4}
        left={isRTL ? 4 : 'unset'}
      >
        <HStack>
          <LanguageSelect
            onChange={changeLanguage}
            value={language}
            ml={[4, 6, 6]}
            bgColor="white"
            languages={SUPPORTED_LANGUAGES}
          />
        </HStack>
      </Box>
    </Flex>
  )
}
