import { Button, Text, Flex } from '@chakra-ui/core'
import { noop } from '@pangaea-holdings/pangaea-checkout'
import { TFunction } from 'i18next'
import Link from 'next/link'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import staticRoutes from '../../static/routes'
import { useTimerCountDown } from '../hooks/useTimerCountDown'

import { useIsShopifyStore } from 'modules/lumin/hooks/useIsShopifyStore'

export interface NavTimerProps {
  getMessage: (t: TFunction, isShopifyStore?: boolean) => string //pass it translated
  endsInMins?: number
  link?: string
  onEnd?: () => void
  loop?: boolean
  isPortal?: boolean
}

const NavTimer: FC<NavTimerProps> = ({
  getMessage,
  endsInMins = 20,
  link = staticRoutes.freeTrial(),
  onEnd = noop,
  loop = false,
  isPortal = false,
}) => {
  const { t } = useTranslation()
  const { isShopifyStore } = useIsShopifyStore()
  const handleTimeCompletion = onEnd
  const { minutes, seconds } = useTimerCountDown(
    endsInMins,
    handleTimeCompletion,
    loop
  )

  const Banner = (
    <Link href={link}>
      <Button
        p={[4, 5]}
        width="100%"
        textAlign="center"
        variant="post-purchase-banner"
        border={0}
      >
        <Flex
          as="h3"
          direction={['column', 'row', 'row']}
          justify="center"
          align="center"
        >
          <Text fontWeight="inherit" whiteSpace="normal" fontSize="inherit">
            {getMessage(t, isShopifyStore)}
          </Text>
          <Text fontSize="inherit" fontWeight="inherit" ml={[0, 2, 2]}>
            {isShopifyStore
              ? t('listiquiz:ends-in', 'ends in: {{minutes}}:{{seconds}}', {
                  minutes,
                  seconds,
                })
              : t(
                  'listiquiz:offer-ends-in',
                  'offer ends in: {{minutes}}:{{seconds}}',
                  { minutes, seconds }
                )}
          </Text>
        </Flex>
      </Button>
    </Link>
  )

  return isPortal ? (
    Banner
  ) : (
    <Flex order={-1} position="sticky" top={0} zIndex="banner">
      {Banner}
    </Flex>
  )
}

export default NavTimer
