import { Select } from '@chakra-ui/core'
import React from 'react'

import i18n from 'modules/i18n'
import { SUPPORTED_LANGUAGES_FULL } from 'modules/i18n/config'

interface Props {}

function LanguageSelector({ selectedLanguage, onChange }) {
  const currentLanguage = i18n.language

  return (
    <Select
      width="100%"
      size="lg"
      fontSize="1rem"
      value={selectedLanguage || currentLanguage}
      onChange={onChange}
      aria-label="change shipping country"
    >
      {Object.keys(SUPPORTED_LANGUAGES_FULL).map((lang) => (
        <option key={lang} value={lang}>
          {SUPPORTED_LANGUAGES_FULL[lang]}
        </option>
      ))}
    </Select>
  )
}

export default LanguageSelector
