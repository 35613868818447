import { useEffect, useState } from 'react'

import { useFeatureFlag } from '../../optimizely/hooks/useFeatureFlag'
import { useCurrentZipCode } from './useCurrentZipCode'

export function useIsNearLocalStore() {
  const [isNearLocalStore, setIsNearLocalStore] = useState(false)
  const walmartZipCodes: { [key: string]: string } = useFeatureFlag(
    'WALMART_ZIPCODES',
    null
  )
  const targetZipCodes: { [key: string]: string } = useFeatureFlag(
    'TARGET_ZIPCODES',
    null
  )

  const isZipCodeActive = !!walmartZipCodes || !!targetZipCodes
  const userZipCode = useCurrentZipCode(isZipCodeActive)

  useEffect(() => {
    if (userZipCode) {
      const isNearLocalStore =
        walmartZipCodes[userZipCode] || targetZipCodes[userZipCode]
      if (isNearLocalStore) {
        setIsNearLocalStore(!!isNearLocalStore)
      }
    }
  }, [userZipCode])

  return isNearLocalStore
}
