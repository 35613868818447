import {
  CurrencyTranslationParams,
  getCookie,
  setCookie,
} from '@pangaea-holdings/pangaea-checkout'
import md5 from 'blueimp-md5'
import { TFunction } from 'i18next'
import { NextPageContext } from 'next'
import { NextRouter } from 'next/router'

import { removeDuplicates } from '../../utils/array'
import { formatCurrencyInteger } from '../../utils/currency'
import { removeQueryParam } from '../../utils/removeQueryParam'

import { PROMOCODE_COOKIE } from 'utils/constants'

export const PROMOTION_EXPIRED_REDIRECT = 'promotion-expired-redirect'


/**
 * Converts an input like this:
 * - "Get 20% off on orders above $20, or 30% off on orders above $30"
 * To:
 * - "Get 20% off on orders above {{currency_$20}}, or 30% off on orders above {{currency_$30}}
 *
 * @param text
 */
export function replaceCurrencies(text) {
  return text.replace(/\$([0-9]+)/g, (match, p) => `{{currency_${match}}}`)
}

/**
 * Receives an input like this:
 * - "Get 20% off on orders above $20, or 30% off on orders above $30"
 * Returns:
 * - {
 *   currency_$20: <20 usd converted to given currency>
 *   currency_$30: <30 usd converted to given currency>
 * }
 */
export function buildCurrencyTranslationParams(
  text: string,
  usdConversionRate: number,
  currency: string
): CurrencyTranslationParams {
  const regex = /\$([0-9]+)/g
  const dollarValues = removeDuplicates(text.match(regex) || [])

  return dollarValues.reduce(
    (acc: CurrencyTranslationParams, value: string) => {
      const valueWithoutDollarSign = Number(value.substr(1))
      acc[`currency_${value}`] = formatCurrencyInteger(
        valueWithoutDollarSign * usdConversionRate * 100,
        currency
      )
      return acc
    },
    {}
  )
}

export function translatePromotionText(
  t: TFunction,
  text: string,
  usdConversionRate: number,
  currency: string
) {
  return t(
    `promotions:${md5(text)}`,
    replaceCurrencies(text),
    buildCurrencyTranslationParams(text, usdConversionRate, currency)
  )
}

export const expiredPromotionRedirect = (redirectTo: string = '/products') => {
  return async (ctx: NextPageContext) => {
    const { res } = ctx
    const expirationDate = new Date()
    expirationDate.setTime(expirationDate.getTime() + 10 * 1000)
    const expires = `expires=${expirationDate.toUTCString()}`
    if (res) {
      res.setHeader('location', redirectTo)
      res.setHeader(
        'Set-cookie',
        `${PROMOTION_EXPIRED_REDIRECT}=true;path=/;${expires}`
      )
      res.statusCode = 302
      res.end()
    }
    return { props: {} }
  }
}

export const getActivePromoCode = (router: NextRouter) => {
  const savedPromCode = getCookie(PROMOCODE_COOKIE)
  const currentPromoCode = router.query.promocode as string
  const promoCode = savedPromCode || currentPromoCode

  if (currentPromoCode) {
    setCookie(PROMOCODE_COOKIE, currentPromoCode, 1)
    removeQueryParam('promocode', router)
  }

  if (promoCode) {
    return promoCode
  }

  return typeof window === 'object' && window['SITEWIDE_PROMO_CODE']
}
