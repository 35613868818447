import { AccountSystemAssets } from '@pangaea-holdings/pangaea-account'
import type {
  Product,
  SizeOption,
  CustomizationOptionValue,
  CustomizationOption,
} from '@pangaea-holdings/pangaea-checkout'

import { TranslatableString } from '../api/types'

import { UsageRoutine } from 'modules/howTo/components/types'
import {
  ApiIngredientInfo,
  IngredientInfo,
} from 'modules/ingredients/ingredientTypes'

export type PDPTabsId = 'skin_type' | 'benefits' | 'key_ingredients'

export type CategoryTypes =
  | 'best-sellers'
  | 'face'
  | 'hair-and-body'
  | 'bundles'
  | 'accessories'

export type CategoryId =
  | 'bundles'
  | 'face'
  | 'face-bundles'
  | 'face-accessories'
  | 'hair-and-body'
  | 'hair-and-body-bundles'
  | 'hair-and-body-accessories'
  | 'accessories'
  | 'best-sellers'
  | 'clearance-sale'
  | 'hair-and-body-v1'

export type ConcernId =
  | 'skin-concerns'
  | 'acne-scars'
  | 'body-odor'
  | 'clogged-pores'
  | 'damaged-hair'
  | 'dark-under-eye-circles'
  | 'dry-hair'
  | 'dry-skin'
  | 'dull-skin'
  | 'enlarged-pores'
  | 'fine-lines'
  | 'irritated-skin'
  | 'oily-skin'
  | 'sun-burn'
  | 'sun-damage'
  | 'uneven-skin-tone'

export enum Category {
  ALL = 'all',
  BEST_SELLERS = 'best-sellers',
  FACE = 'face',
  HAIR_AND_BODY = 'hair-and-body',
  BUNDLES = 'bundles',
  ACCESSORIES = 'accessories',
  CLEARANCE_SALE = 'clearance-sale',
}

export interface ProductIngredient {
  name?: string
  description: string
  imageUrl?: string
}

export interface ApiProductIngredient {
  name?: TranslatableString
  description: TranslatableString
  imageUrl?: string
}

export interface ApiProductUse {
  title?: TranslatableString
  description?: TranslatableString[]
  image?: string
}

export type ApiProductUsage = {
  videoUrl?: string
  thumbnailUrl?: string
  headline: TranslatableString
  steps?: TranslatableString[]
  stepTitle?: TranslatableString
}

export type ApiUsageRoutine = {
  day: ApiProductUsage[]
  night: ApiProductUsage[]
  shower?: ApiProductUsage[]
  anytime?: ApiProductUsage[]
  hideDayNightToggle?: boolean
}

export interface ApiAccountSystemAssets {
  desc: TranslatableString
  imageUrl: string
  colorBasedImages?: Record<string, string>
}

export interface ApiProductDetails {
  id: number
  category: CategoryId[]
  headline: TranslatableString
  intro?: TranslatableString
  description: TranslatableString
  otherDescriptions?: TranslatableString[]
  helpsWith?: ApiProductUse[]
  ingredients: ApiProductIngredient[]
  allIngredients?: ApiProductIngredient[]
  otherImageUrls: string[]
  usageImageUrl?: string
  usage?: ApiProductUse[]
  twoRowUsage?: boolean
  recommendedProductIds?: number[]
  badge_url?: string
  sizePack?: TranslatableString
  plpImageUrl?: string
  blurb?: string
  disclaimerSizeOptions?: number[]
  accountSystemAssets?: ApiAccountSystemAssets

  isVisibleInAccountSystem?: boolean
  isHiddenInAccountSystem?: boolean
  benefits?: { name: TranslatableString; icon: string }[]
  UsageRoutine?: UsageRoutine
  skinType?: TranslatableString
  setsWithProduct?: number[]
  ingredientsInfo?: ApiIngredientInfo[]
  concerns?: ConcernId[]
  isOneMonthSupply?: boolean
  isOtpOnly?: boolean
  isHidden?: boolean
  promo?: {
    percentOff: number
    code: string
  }
  clearancePromoInfo?: { [k: string]: number[] }
  defaultSizeStrategy?: SizeStrategy
  importantInfo?: TranslatableString
}

export interface ProductUse {
  title?: string
  description?: string[]
  image?: string
}

export interface ProductDetails {
  id: number
  category: CategoryId[]
  headline: string
  intro?: string
  description: string
  otherDescriptions?: string[]
  helpsWith?: ProductUse[]
  ingredients: ProductIngredient[]
  allIngredients?: ProductIngredient[]
  otherImageUrls: string[]
  usageImageUrl?: string
  usage?: ProductUse[]
  twoRowUsage?: boolean
  recommendedProductIds?: number[]
  badge_url?: string
  sizePack?: string
  plpImageUrl?: string
  blurb?: string
  accountSystemAssets?: AccountSystemAssets
  isVisibleInAccountSystem?: boolean
  isHiddenInAccountSystem?: boolean
  benefits?: { name: string; icon: string }[]
  UsageRoutine?: UsageRoutine
  skinType?: string
  setsWithProduct?: number[]
  ingredientsInfo?: IngredientInfo[]
  concerns?: ConcernId[]
  isOneMonthSupply?: boolean
  isOtpOnly?: boolean
  promo?: {
    percentOff: number
    code: string
  }
  isHidden?: boolean
  disclaimerSizeOptions?: number[]
  isPromoVariant?: boolean
  clearancePromoInfo?: { [k: string]: number[] }
  badgeText?: string
  defaultSizeStrategy?: SizeStrategy
  importantInfo?: string
}

export type ProductCategoryConfig = {
  name?: string
  title?: string
  description?: string
  showAll?: boolean
  slug: string
  bgColor: string
}

export type ProductCategory = Record<
  Category,
  ProductCategoryConfig[] | ProductCategoryConfig
>

export interface ProductCustomization {
  productId: number
  productSizeOptionId: number
  productCustomizationOptionIds: number[]
}

export interface LinkedImage {
  imageUrl: string
  href: string
}

export type ProductWithDetails = ProductDetails & Product

export type ProductsByCategory = Record<string, ProductWithDetails[]>

export interface ProductRenewalDiscountRule {
  percentageOff: number
  subscriptionDiscountKey: string
}

export type ProductsPageDetail = {
  value: string
  title: string
  titleTag: string
  heroImage: string
  heroImageMobile: string
  header: string
  subHeader: string
  description: string
  hasButton?: boolean
  centerHeoroHeader?: boolean
  highlights?: { title: string; description: string }[]
  navFixedPositionOrigin?: number
  filterNavFixedPositionOrigin?: number
}

export type ProductsError = {
  message: string
}

export type ProductConcerns = {
  value: ConcernId
  title: string
}

export type PriceRange = {
  minPrice: number
  maxPrice: number
}

export type PriceRangeOption = {
  value: PriceRange
  title: string
}

export interface CustomizationOptionValueWithAvailabilty
  extends CustomizationOptionValue {
  outOfStock?: boolean
}
export interface CustomizationOptionWithAvailabilty
  extends CustomizationOption {
  options: {
    [id: number]: CustomizationOptionValueWithAvailabilty
  }
}
export interface CustomizationOptionsWithAvailabilty {
  [key: string]: CustomizationOptionWithAvailabilty
}
export interface SizeOptionWithAvailabilty extends SizeOption {
  outOfStock?: boolean
}

export interface SizeOptionsWithAvailabilty {
  [x: string]: SizeOptionWithAvailabilty
}

export enum SizeStrategy {
  DEFAULT,
  PROMO_SIZE_DEFAULT,
  HIGHEST_SIZE_DEFAULT,
}
