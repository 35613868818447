import { Box, Select } from '@chakra-ui/core'
import {
  CheckoutContext,
  CountryResponse,
  getCookie,
  isSupportedCurrency,
  shippingCountrySelected,
  SHIPPING_COUNTRY_COOKIE,
  updateCurrency,
} from '@pangaea-holdings/pangaea-checkout'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CURRENCIES, CurrencyData } from '../currencies'

export const useShippingCountrySelectState = () => {
  const { dispatch, state } = useContext(CheckoutContext)
  const { shippableCountries } = state.countries
  const currentCountryId = state.countries.selectedCountryId

  const [selectorCountryId, setSelectorCountryId] = useState<string>(
    currentCountryId || ''
  )

  const countries = shippableCountries.reduce((sCountries, country) => {
    sCountries[country.id] = {
      ...country,
      ...CURRENCIES[country.id],
    }
    return sCountries
  }, {})

  const countryId =
    currentCountryId ||
    (typeof window !== 'undefined' && getCookie(SHIPPING_COUNTRY_COOKIE)) ||
    'US'

  const currentCountry =
    countries[countryId] || countries['US'] || CURRENCIES['US']

  const selectorCountry = countries[selectorCountryId] || currentCountry

  const handleChangeShippingCountry = async () => {
    dispatch(shippingCountrySelected(selectorCountryId))
    const currencyCode = isSupportedCurrency(
      countries[selectorCountryId].currency_code
    )
      ? countries[selectorCountryId].currency_code
      : 'USD'
    await dispatch(updateCurrency(currencyCode))
  }

  return {
    countries,
    currentCountry,
    selectorCountry,
    selectorCountryId,
    setSelectorCountryId,
    handleChangeShippingCountry,
  }
}

export const CountrySelect = ({ countries, selectedCountry, onChange }) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        '& select': {
          pl: '50px',
          pt: 0,
        },
      }}
      position="relative"
    >
      <Box
        position="absolute"
        as="svg"
        width="26px"
        height="20px"
        viewBox="0 0 64 48"
        top="50%"
        transform="translateY(-50%)"
        left="15px"
        zIndex={1}
      >
        <image xlinkHref={selectedCountry.flag} width="64" height="48" />
      </Box>

      <Select
        width="100%"
        size="lg"
        fontSize="1rem"
        value={selectedCountry.id}
        onChange={onChange ? (e) => onChange(e.target.value) : undefined}
        aria-label="change shipping country"
      >
        {Object.values<CountryResponse & CurrencyData>(countries).map(
          (country) => (
            <option key={country.id} value={country.id}>
              {t(`checkout:country-${country.id.toLowerCase()}`, country.name)}{' '}
              ({country.currency_code} {country.currency_symbol})
            </option>
          )
        )}
      </Select>
    </Box>
  )
}
