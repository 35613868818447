import * as Sentry from '@sentry/browser'
import { TFunction } from 'i18next'

import {
  TextProps,
  FreeTrialExtraProductData,
  FreeTrialCatalog,
  RenewalDiscount,
  RenewalDiscountConfigVariants,
  TrialConfig,
} from '../types'

export const productSlugToOfferMap = {
  'classic-maintenance-for-men': 'classic-maintanence',
  'classic-maintenance-eye-essentials': 'anti-fatigue-essentials-set',
}

export const RENEWAL_DISCOUNT_VARIANTS: Record<
  RenewalDiscountConfigVariants,
  RenewalDiscount
> = {
  first10: {
    discount: 0.1,
    numberOfRenewal: 1,
    offer: 'first-renewal-discount-10',
  },
  firstThree10: {
    discount: 0.1,
    numberOfRenewal: 3,
    offer: 'first-three-renewal-discount-10',
  },
  first20: {
    discount: 0.2,
    numberOfRenewal: 1,
    offer: 'first-renewal-discount-20',
  },
  firstThree20: {
    discount: 0.2,
    numberOfRenewal: 3,
    offer: 'first-three-renewal-discount-20',
  },
}

export const getTrialConfig = (t: TFunction, page: string): TrialConfig => {
  const catalog: { [page in FreeTrialCatalog]: TrialConfig } = {
    'free-trial': {
      offers: [
        'classic-maintanence',
        { offer: 'anti-fatigue-essentials-set', trending: true },
        'age-management',
      ],
      defaultPrice: 795,
      title: 'Exclusive Free Trial',
    },
    'free-trial-3': {
      offers: [
        { offer: 'classic-maintanence', trending: true, order: [1, 1, 2] },
        { offer: 'age-management', order: [2, 2, 1] },
        { offer: 'correction-trio', order: [3, 3, 3] },
      ],
      defaultPrice: 795,
      title: 'Exclusive Free Trial',
      enableRenewalDiscount: true,
      redirectToBaseTrialPage: true,
    },
    'free-trial-10': {
      offers: [
        { offer: 'classic-maintanence', trending: true, order: [1, 1, 2] },
        { offer: 'age-management', order: [2, 2, 1] },
        { offer: 'correction-trio', order: [3, 3, 3] },
      ],
      defaultPrice: 1295,
      title: 'Exclusive Free Trial',
      redirectToBaseTrialPage: true,
    },
    'free-trial-4': {
      offers: [
        { offer: 'classic-maintanence', trending: true, order: [1, 1, 2] },
        { offer: 'age-management', order: [2, 2, 1] },
        { offer: 'correction-trio', order: [3, 3, 3] },
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
      enableRenewalDiscount: true,
      redirectToBaseTrialPage: true,
    },
    'free-trial-5': {
      offers: [{ offer: 'youth-management-set', trending: true }],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
      deprecated: true,
    },
    'free-trial-8': {
      offers: [{ offer: 'youth-management-set', trending: true }],
      defaultPrice: 795,
      title: 'Exclusive Free Trial',
      redirectToBaseTrialPage: true,
    },
    'free-trial-6': {
      offers: [
        'classic-maintanence',
        { offer: 'youth-management-set', trending: true },
        'correction-trio',
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
      deprecated: true,
    },
    'free-trial-9': {
      offers: [
        'classic-maintanence',
        { offer: 'youth-management-set', trending: true },
        'correction-trio',
      ],
      defaultPrice: 795,
      title: 'Exclusive Free Trial',
      redirectToBaseTrialPage: true,
    },
    'free-trial-7': {
      offers: [
        { offer: 'classic-maintanence', trending: true },
        'age-management',
        'correction-trio',
      ],
      defaultPrice: 795,
      title: 'Exclusive Free Trial',
      redirectToBaseTrialPage: true,
    },
    'free-trial-11': {
      offers: [
        { offer: 'classic-maintanence', order: [2, 2, 1] },
        {
          offer: 'anti-fatigue-essentials-set',
          trending: true,
          order: [1, 1, 2],
        },
        { offer: 'age-management', order: [3, 3, 3] },
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
      enableRenewalDiscount: true,
      redirectToBaseTrialPage: true,
    },
    'free-trial-12': {
      offers: [
        { offer: 'classic-maintanence', order: [2, 2, 1] },
        {
          offer: 'anti-fatigue-essentials-set',
          trending: true,
          order: [1, 1, 2],
        },
        { offer: 'age-management', order: [3, 3, 3] },
      ],
      defaultPrice: 795,
      title: 'Exclusive Free Trial',
      enableRenewalDiscount: true,
      redirectToBaseTrialPage: true,
    },
    'free-trial-hello-fresh': {
      offers: [
        { offer: 'classic-maintanence', order: [2, 2, 1] },
        {
          offer: 'anti-fatigue-essentials-set',
          trending: true,
          order: [1, 1, 2],
        },
        { offer: 'age-management', order: [3, 3, 3] },
      ],
      defaultPrice: 100,
      title: 'Exclusive Free Trial',
      enableRenewalDiscount: true,
    },
    'free-trial-uv-4': {
      offers: [
        {
          offer: 'classic-maintenance-set-with-spf',
          trending: true,
          order: [1, 1, 2],
        },
        { offer: 'age-management-set-with-spf', order: [2, 2, 1] },
        { offer: 'correction-trio-with-spf', order: [3, 3, 3] },
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
      enableRenewalDiscount: true,
      redirectToBaseTrialPage: true,
    },
    'free-trial-uv-11': {
      offers: [
        {
          offer: 'classic-maintenance-set-with-spf',
          trending: true,
          order: [1, 1, 2],
        },
        { offer: 'age-management-set-with-spf', order: [2, 2, 1] },
        { offer: 'anti-fatigue-essentials-set-spf', order: [3, 3, 3] },
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
      enableRenewalDiscount: true,
      redirectToBaseTrialPage: true,
    },
    'offers-subscriptions-1': {
      isNoFreeTrial: true,
      offers: [
        { offer: 'classic-maintanence', trending: true },
        'correction-trio',
        'anti-fatigue-essentials-set',
      ],
      defaultPrice: 795,
      title: 'Exclusive Discount',
      hasNoFreeContent: true,
      hasSubscribeEmailPopup: true,
      offerExperimentName: 'Additional Discount - 20%',
      offerType: 'discount',
      coupon: 'DISCOUNT_2021_20',
      preCheckoutMessage: t(
        'offers:an-additonal-20-off-will-be-applied-to-the-first-shipment',
        'An additional 20% off will be applied to the first shipment '
      ),
      discountRate: 0.2,
      contentVariant: {
        heroTitle: t(
          'offers:enjoy-your-exclusive-20%-discount',
          'Enjoy Your Exclusive 20% Discount!'
        ),
        heroSubtitle: t(
          'offers:for-a-limited-time-we-are-offering-20',
          'For a limited time, we are offering 20% off the first shipment on top of already reduced subscription pricing, applicable to our most popular sets. Enjoy 2 months of top-quality skincare products for a fraction of the price!'
        ),
      },
    },
    // 'offers-subscriptions-2': {
    //   offers: [
    //     { offer: 'classic-maintanence', trending: true },
    //     'correction-trio',
    //     'anti-fatigue-essentials-set',
    //   ],
    //   defaultPrice: 795,
    //   title: 'Exclusive Discount',
    //   hasNoFreeContent: true,
    //   hasSubscribeEmailPopup: true,
    //   offerExperimentName: 'Additional Discount - 30%',
    //   offerType: 'discount',
    //   coupon: 'DISCOUNT_2021_30',
    //   preCheckoutMessage: t(
    //     'offers:an-additonal-30-off-will-be-applied-to-the-first-shipment',
    //     'An additional 30% off will be applied to the first shipment '
    //   ),
    //   discountRate: 0.3,
    //   contentVariant: {
    //     heroTitle: t(
    //       'offers:enjoy-your-exclusive-30%-discount',
    //       'Enjoy Your Exclusive 30% Discount!'
    //     ),
    //     heroSubtitle: t(
    //       'offers:our-skincare-products-are-jointly-developed-by-the-best-30',
    //       'Our skincare products are jointly developed by the best in Los Angeles and Seoul. For a limited time, we are offering 30% off the first shipment on top of already reduced subscription pricing, applicable to our most popular sets. Enjoy 2 months of top-quality skincare products for a fraction of the price!'
    //     ),
    //   },
    // },
    // 'offers-gift-haircare-1': {
    //   offers: [
    //     { offer: 'classic-maintanence', trending: true },
    //     'correction-trio',
    //     'anti-fatigue-essentials-set',
    //   ],
    //   defaultPrice: 795,
    //   title: 'Exclusive Free Gift',
    //   hasNoFreeContent: true,
    //   hasSubscribeEmailPopup: true,
    //   offerExperimentName: 'Haircare set (1st order only)',
    //   offerDisplayName: 'Haircare Set',
    //   offerType: 'freeGift',
    //   contentVariant: {
    //     heroTitle: t(
    //       'freeTrial:enjoy-your-exclusive-free-gift',
    //       'Enjoy Your Exclusive Free Gift'
    //     ),
    //     heroSubtitle: t(
    //       'freeTrial:offer-subscription-free-gift',
    //       'Our expert curated products are jointly developed by the best in Los Angeles and Seoul. For a limited time, we are offering free gifts to you based on your personal skin management needs. Simply answer a couple of questions and start to enjoy #YourBestLookEver.'
    //     ),
    //   },
    //   gift: {
    //     title: 'Haircare Set (Shampoo + Conditioner)',
    //     imageUrl: '/img/free-trial/gifts/hairCareSet.png',
    //   },
    // },
    // 'offers-gift-haircare-2': {
    //   offers: [
    //     { offer: 'classic-maintanence', trending: true },
    //     'correction-trio',
    //     'anti-fatigue-essentials-set',
    //   ],
    //   defaultPrice: 795,
    //   title: 'Exclusive Free Gift',
    //   hasNoFreeContent: true,
    //   hasSubscribeEmailPopup: true,
    //   offerExperimentName: 'Haircare set (First 3 order only)',
    //   offerDisplayName: 'Haircare Set',
    //   offerType: 'freeGift',
    //   contentVariant: {
    //     heroTitle: t(
    //       'freeTrial:enjoy-your-exclusive-free-gift',
    //       'Enjoy Your Exclusive Free Gift'
    //     ),
    //     heroSubtitle: t(
    //       'freeTrial:offer-subscription-free-gift',
    //       'Our expert curated products are jointly developed by the best in Los Angeles and Seoul. For a limited time, we are offering free gifts to you based on your personal skin management needs. Simply answer a couple of questions and start to enjoy #YourBestLookEver.'
    //     ),
    //   },
    //   gift: {
    //     title: 'Haircare Set (Shampoo + Conditioner)',
    //     imageUrl: '/img/free-trial/gifts/hairCareSet.png',
    //   },
    // },
    // 'offers-gift-facemask-recovery-oil-1': {
    //   offers: [
    //     { offer: 'classic-maintanence', trending: true },
    //     'correction-trio',
    //     'anti-fatigue-essentials-set',
    //   ],
    //   defaultPrice: 795,
    //   title: 'Exclusive Free Gift',
    //   hasNoFreeContent: true,
    //   hasSubscribeEmailPopup: true,
    //   offerExperimentName: 'Face Mask + Recovery Oil (1st order only)',
    //   offerDisplayName: 'Face Mask + Recovery Oil',
    //   offerType: 'freeGift',
    //   contentVariant: {
    //     heroTitle: t(
    //       'freeTrial:enjoy-your-exclusive-free-gift',
    //       'Enjoy Your Exclusive Free Gift'
    //     ),
    //     heroSubtitle: t(
    //       'freeTrial:offer-subscription-free-gift',
    //       'Our expert curated products are jointly developed by the best in Los Angeles and Seoul. For a limited time, we are offering free gifts to you based on your personal skin management needs. Simply answer a couple of questions and start to enjoy #YourBestLookEver.'
    //     ),
    //   },
    //   gift: {
    //     title: 'Recovery Oil + Face Mask (5 Pack)',
    //     imageUrl: '/img/free-trial/gifts/faceMaskAndRecoveryOil.png',
    //   },
    // },
    // 'offers-gift-facemask-recovery-oil-2': {
    //   offers: [
    //     { offer: 'classic-maintanence', trending: true },
    //     'correction-trio',
    //     'anti-fatigue-essentials-set',
    //   ],
    //   defaultPrice: 795,
    //   title: 'Exclusive Free Gift',
    //   hasNoFreeContent: true,
    //   hasSubscribeEmailPopup: true,
    //   offerExperimentName: 'Face Mask + Recovery Oil (First 3 order only)',
    //   offerDisplayName: 'Face Mask + Recovery Oil',
    //   offerType: 'freeGift',
    //   contentVariant: {
    //     heroTitle: t(
    //       'freeTrial:enjoy-your-exclusive-free-gift',
    //       'Enjoy Your Exclusive Free Gift'
    //     ),
    //     heroSubtitle: t(
    //       'freeTrial:offer-subscription-free-gift',
    //       'Our expert curated products are jointly developed by the best in Los Angeles and Seoul. For a limited time, we are offering free gifts to you based on your personal skin management needs. Simply answer a couple of questions and start to enjoy #YourBestLookEver.'
    //     ),
    //   },
    //   gift: {
    //     title: 'Recovery Oil + Face Mask (5 Pack)',
    //     imageUrl: '/img/free-trial/gifts/faceMaskAndRecoveryOil.png',
    //   },
    // },
    'modern-bathroom': {
      offers: [
        'fundamental-duo',
        { offer: 'modern-bathroom', trending: true },
        'must-haves',
      ],
      defaultPrice: 495, // This page is currently 495 default
      title: 'Modern Bathroom Set',
      templateVariant: 'modernBathroom',
      deprecated: true,
    },

    'modern-bathroom-7': {
      offers: [
        'fundamental-duo',
        { offer: 'modern-bathroom', trending: true },
        'must-haves',
      ],
      defaultPrice: 1295,
      title: 'Modern Bathroom Set',
      templateVariant: 'modernBathroom',
      deprecated: true,
    },

    'modern-bathroom-4': {
      offers: [
        'fundamental-duo',
        { offer: 'modern-bathroom', trending: true },
        'must-haves',
      ],
      defaultPrice: 795,
      title: 'Modern Bathroom Set',
      templateVariant: 'modernBathroom',
      deprecated: true,
    },
    'modern-bathroom-2': {
      title: 'Modern Bathroom Set',
      offers: [{ offer: 'up-top-management', trending: true }],
      defaultPrice: 495,
      contentVariant: {
        heroImage: {
          deskTop:
            'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/UpTopManagementDesktop2.jpg?v=1608733346',
          mobile:
            'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/UpTopManagementMobile2.jpg?v=1608733858',
        },
      },
      templateVariant: 'modernBathroom',
      deprecated: true,
    },
    'modern-bathroom-5': {
      title: 'Modern Bathroom Set',
      offers: [{ offer: 'up-top-management', trending: true }],
      defaultPrice: 795,
      contentVariant: {
        heroImage: {
          deskTop:
            'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/UpTopManagementDesktop2.jpg?v=1608733346',
          mobile:
            'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/UpTopManagementMobile2.jpg?v=1608733858',
        },
      },
      templateVariant: 'modernBathroom',
      deprecated: true,
    },
    'modern-bathroom-3': {
      offers: [
        'modern-bathroom',
        { offer: 'up-top-management', trending: true },
        'must-haves',
      ],
      defaultPrice: 495,
      title: 'Modern Bathroom Set',
      templateVariant: 'modernBathroom',
      deprecated: true,
    },
    'modern-bathroom-6': {
      offers: [
        'modern-bathroom',
        { offer: 'up-top-management', trending: true },
        'must-haves',
      ],
      defaultPrice: 795,
      title: 'Modern Bathroom Set',
      templateVariant: 'modernBathroom',
      deprecated: true,
    },
    'free-trial-lsets': {
      offers: [
        { offer: 'classic-maintanence', price: 495 },
        { offer: 'anti-fatigue-essentials-set', trending: true },
        { offer: 'expert-maintenance-set', price: 995 },
      ],

      defaultPrice: 795,
      title: 'Exclusive Free Trial',
      redirectToBaseTrialPage: true,
    },
    'free-trial-lsets-5': {
      offers: [
        { offer: 'classic-maintanence' },
        { offer: 'anti-fatigue-essentials-set', trending: true },
        { offer: 'expert-maintenance-set', price: 995 },
      ],
      defaultPrice: 795,
      title: 'Exclusive Free Trial',
      redirectToBaseTrialPage: true,
    },
    'free-trial-lsets-4': {
      offers: [
        { offer: 'classic-maintanence', price: 1095 },
        { offer: 'anti-fatigue-essentials-set', trending: true, price: 1295 },
        { offer: 'expert-maintenance-set', price: 1495 },
      ],

      defaultPrice: 795,
      title: 'Exclusive Free Trial',
      redirectToBaseTrialPage: true,
    },
    'free-trial-lsets-2': {
      offers: [{ offer: 'anti-fatigue-essentials-set', trending: true }],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
      redirectToBaseTrialPage: true,
    },
    'free-trial-lsets-3': {
      offers: [{ offer: 'anti-fatigue-essentials-set', trending: true }],
      defaultPrice: 795,
      title: 'Exclusive Free Trial',
      redirectToBaseTrialPage: true,
    },
    'free-trial-lsets-uv-2': {
      offers: [{ offer: 'anti-fatigue-essentials-with-spf', trending: true }],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
      redirectToBaseTrialPage: true,
    },
    'free-trial-antifatigue': {
      offers: [{ offer: 'anti-fatigue-essentials-set' }],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
      contentVariant: {
        heroSubtitle: t(
          'freeTrial:our-premium-products-are-developed-by-experts',
          'Our premium products are developed by skincare experts in Los Angeles and Seoul. For a limited time, we’re offering a free trial for a set containing 4 key products that together form a routine designed specifically for your personal skin management needs.'
        ),
        heroImage: {
          deskTop:
            'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/antiFatigueHeroDesktop.jpg?v=1608470227',
          mobile:
            'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/antiFatigueHeroMobile.jpg?v=1608470224',
        },
        topNavImage:
          'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/antiFatigueBgHeader.jpg?v=1608473357',
      },
    },
    'up-top': {
      title: 'Up Top Management Set',
      offers: ['up-top-management'],
      defaultPrice: 495,
      contentVariant: {
        heroImage: {
          deskTop:
            'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/UpTopManagementDesktop2.jpg?v=1608733346',
          mobile:
            'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/UpTopManagementMobile2.jpg?v=1608733858',
        },
      },
      templateVariant: 'modernBathroom',
    },
    'free-trial-classic-maintenance-set': {
      offers: [
        { offer: 'classic-maintanence', recommended: true, order: [1, 1, 2] },
        { offer: 'fundamental-duo', order: [2, 2, 1] },
        { offer: 'anti-fatigue-essentials-set', order: [3, 3, 3] },
      ],

      defaultPrice: 495,
      title: 'Exclusive Free Trial',
    },
    'free-trial-age-management-set': {
      offers: [
        { offer: 'age-management', recommended: true, order: [1, 1, 2] },
        { offer: 'classic-maintanence', order: [2, 2, 1] },
        { offer: 'anti-fatigue-essentials-set', order: [3, 3, 3] },
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
    },
    'free-trial-anti-fatigue-essentials-set': {
      offers: [
        {
          offer: 'anti-fatigue-essentials-set',
          recommended: true,
          order: [1, 1, 2],
        },
        { offer: 'no-baggage-trio', order: [2, 2, 1] },
        { offer: 'fundamental-duo', order: [3, 3, 3] },
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
    },
    'free-trial-quarterly-fundamental-duo': {
      offers: [
        {
          offer: 'fundamental-duo-3-month-supply',
          recommended: true,
          order: [1, 1, 2],
        },
        { offer: 'classic-maintanence', order: [2, 2, 1] },
        { offer: 'anti-fatigue-essentials-set', order: [3, 3, 3] },
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
    },
    'free-trial-fundamental-duo-set-wtp': {
      offers: [
        {
          offer: 'fundamental-duo',
          recommended: true,
        },
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
    },
    'free-trial-fundamental-duo-set-wtp-wmt-oily-normal': {
      offers: [
        {
          offer: 'fundamental-duo-oily-normal-set',
          recommended: true,
        },
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
    },
    'free-trial-fundamental-duo-set-wtp-wmt-dry': {
      offers: [
        {
          offer: 'fundamental-duo-dry-set',
          recommended: true,
        },
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
    },
    'free-trial-no-baggage-trio': {
      offers: [
        {
          offer: 'no-baggage-trio',
          recommended: true,
          order: [1, 1, 2],
        },
        { offer: 'anti-fatigue-essentials-set', order: [2, 2, 1] },
        { offer: 'fundamental-duo', order: [3, 3, 3] },
      ],
      defaultPrice: 495,
      title: 'Exclusive Free Trial',
    },
  }

  return catalog[page]
}

/**
 * This is data specific to showing a free trial card / modal
 * This is linked by product slug
 *
 */
export function getFreeTrialExtraProductData(
  t: TFunction,
  slug: string,
  isNewCopy: boolean = false
): FreeTrialExtraProductData | undefined {
  const data: FreeTrialExtraProductData[] = [
    {
      productSlug: 'age-management-collection-for-men',
      desc: t(
        'freeTrial:age-management-desc',
        'Make sure to keep yourself in your prime. A targeted age recovery program that restores and maintains a manly, vigorous look.'
      ),
      helpsWith: isNewCopy
        ? [
            t(
              'freeTrial:dark-circle-under-your-eyes',
              'Dark circles under your eyes'
            ),
            t('freeTrial:fine-lines-and-wrinkles', 'Fine lines and wrinkles'),
            t('freeTrial:proper-skin-hydration', 'Proper skin hydration'),
          ]
        : [
            t(
              'freeTrial:repairing-sun-damage-and-evening-skin-tone',
              'Repairing sun damage and evening skin tone'
            ),
            t('freeTrial:undereye-repair-age-management', 'Undereye repair'),
            t(
              'freeTrial:overnight-hydration-age-management',
              'Overnight Hydration'
            ),
          ],
      heroImage:
        'https://i.shgcdn.com/d66ca0f4-e243-46aa-b5a6-09e9eb93fd64/-/format/auto/-/preview/3000x3000/-/quality/lighter/',
      kitIncludesDesc: t(
        'freeTrial:what-you-need-to-keep-your-skin',
        'What you need to keep your skin looking and feeling young'
      ),
      kitIncludes: [
        t('products:moisturizing-balm.name', 'Moisturizing Balm'),
        t('products:anti-wrinkle-serum.name', 'Anti-Wrinkle Serum'),
        t('products:dark-circle-defense.name', 'Dark Circle Defense'),
      ],
      helpsWithDesc: t(
        'freeTrial:age-management-helps-with-desc',
        'Focuses on tired looks, dark circles and signs of aging like fine lines and wrinkles. The moisturizer is a staple of any skincare routine and promotes long term skin health and maintenance.'
      ),
    },
    {
      productSlug: 'classic-maintenance-for-men',
      desc: t(
        'freeTrial:classic-maintanence-desc',
        'The fundamentals of looking good. Ideal for athletes, skincare beginners, younger guys, and low-maintenance guys.'
      ),
      helpsWith: isNewCopy
        ? [
            t('freeTrial:overall-skin-health', 'Overall skin health'),
            t('freeTrial:deep-cleansing-of-pores', 'Deep cleansing of pores'),
            t('freeTrial:proper-skin-hydration', 'Proper skin hydration'),
          ]
        : [
            t(
              'freeTrial:hyperpigmentation-soothing-skin',
              'Hyperpigmentation and soothing irritated skin'
            ),
            t('freeTrial:overall-skin-health', 'Overall skin health'),
            t('freeTrial:deep-cleanse-of-pores', 'Deep cleanse of pores'),
          ],
      heroImage:
        'https://i.shgcdn.com/99b324ce-47d5-4d7d-8346-81ee240eee17/-/format/auto/-/preview/3000x3000/-/quality/lighter/',
      kitIncludesDesc: t(
        'freeTrial:what-you-need-for-fundamentals-of-looking-good',
        'What you need for the fundamentals of looking good'
      ),
      kitIncludes: [
        t('products:moisturizing-balm.name', 'Moisturizing Balm'),
        t('products:charcoal-cleanser.name', 'Charcoal Cleanser'),
        t('products:exfoliating-rub.name', 'Exfoliating Rub'),
      ],
      helpsWithDesc: t(
        'freeTrial:classic-maintanence-helps-with-desc',
        'Improve acne scarring and shaving irritation, while boosting skin health and deeply cleansing your pores. Moisturize, cleanse, and exfoliate to develop a routine that keeps you looking fresh and healthy.'
      ),
      concerns: [
        'acne-scars',
        'oily-skin',
        'dry-skin',
        'overall-skin-health',
        'wrinkles',
        'sun-damage',
        'uneven-tone',
      ],
    },
    {
      productSlug: 'correction-trio-for-men',
      desc: t(
        'freeTrial:correction-trio-desc',
        "Boost your under-eyes, reinvigorate dull skin, and reduce the effect of hyper-pigmentation. You'll look ready to take on even the most grueling days."
      ),
      helpsWith: isNewCopy
        ? [
            t(
              'freeTrial:dark-circles-under-your-eyes',
              'Dark circles under your eyes'
            ),
            t('freeTrial:proper-skin-hydration', 'Proper skin hydration'),
            t('freeTrial:skin-damage-recovery', 'Skin damage recovery'),
            t('freeTrial:evening-out-skin-tone', 'Evening out skin tone'),
          ]
        : [
            t('freeTrial:reducing-dark-circles', 'Reducing dark circles'),
            t('freeTrial:firming-hydrating-skin', 'Firming and hydrating skin'),
            t(
              'freeTrial:Liven-brighten-appearance',
              'Liven and brighten overall appearance'
            ),
          ],
      heroImage:
        'https://i.shgcdn.com/729f0db5-52c5-4d1b-bb12-8061184f81e3/-/format/auto/-/preview/3000x3000/-/quality/lighter/',
      kitIncludesDesc: t(
        'freeTrial:what-you-need-to-get-your-healthy-look-back',
        'What you need to get your healthy look back'
      ),
      kitIncludes: [
        t('products:moisturizing-balm.name', 'Moisturizing Balm'),
        t('products:dark-circle-defense.name', 'Dark Circle Defense'),
        t('products:exfoliating-rub.name', 'Exfoliating Rub'),
      ],
      helpsWithDesc: t(
        'freeTrial:correction-trio-helps-with-desc',
        'Focuses on tired looks, dark circles and puffy eyes while providing general skin benefits including combatting sun damage, aging, shaving irritation and fine lines while promoting oil regulation, a more even skin tone and a faster regeneration of healthy skin cells.'
      ),
    },
    {
      productSlug: 'classic-maintenance-eye-essentials',
      desc: t(
        'freeTrial:anti-fatigue-essentials-desc',
        'Stay looking fresh every day of the week with 4 simple essentials. Deeply clean gunk out from your pores, reduce under-eye bags, and look better in just 90 seconds.'
      ),
      helpsWith: isNewCopy
        ? [
            t(
              'freeTrial:dark-circles-under-your-eyes',
              'Dark circles under your eyes'
            ),
            t('freeTrial:proper-skin-hydration', 'Proper skin hydration'),
            t('freeTrial:skin-damage-recovery', 'Skin damage recovery'),
            t('freeTrial:evening-out-skin-tone', 'Evening out skin tone'),
          ]
        : [
            t('freeTrial:under-eye-bags', 'Under-eye bags'),
            t('freeTrial:acne-scarring', 'Acne scarring'),
            t('freeTrial:repairing-sun-damage', 'Repairing sun damage'),
          ],
      heroImage:
        'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/AntiFatigue2_1.png?v=1600987628',
      kitIncludesDesc: t(
        'freeTrial:what-you-need-to-look-refereshed-and-rest-every-day',
        'What you need to look refreshed and rested every day'
      ),
      kitIncludes: [
        t('products:moisturizing-balm.name', 'Moisturizing Balm'),
        t('products:charcoal-cleanser.name', 'Charcoal Cleanser'),
        t('products:exfoliating-rub.name', 'Exfoliating Rub'),
        t('products:dark-circle-defense.name', 'Dark Circle Defense'),
      ],
      helpsWithDesc: t(
        'freeTrial:anti-fatigue-helps-with-desc',
        "Focuses on oil and dirt removal, soothing skin irritation, tired-looking eye-bags, and intense hydration. A 90 second daily refresh to keep your skin looking it's best."
      ),
      concerns: [
        'dark-circles',
        'looking-tired',
        'acne-scars',
        'oily-skin',
        'dry-skin',
        'overall-skin-health',
        'wrinkles',
        'sun-damage',
        'uneven-tone',
      ],
    },
    {
      productSlug: 'advanced-maintenance-set',
      desc: t(
        'freeTrial:expert-maintenance-set-desc',
        "Built to cover your skincare needs from A-Z. Meant to maintain your skin's health and provide a preventative solution for skin dullness, early aging signs, and under-eye bags."
      ),
      helpsWith: [
        t('freeTrial:overall-deep-skin', 'Overall deep skin health'),
        t('freeTrial:under-eye-bag', 'Under-eye bag and wrinkle defense'),
        t('freeTrial:firming-and-hydrating', 'Firming and hydrating skin'),
      ],
      heroImage:
        'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/FT_ExpertMaintenance_2.png?v=1600987814',
      kitIncludes: [
        t('products:moisturizing-balm.name', 'Moisturizing Balm'),
        t('products:charcoal-cleanser.name', 'Charcoal Cleanser'),
        t('products:exfoliating-rub.name', 'Exfoliating Rub'),
        t('products:dark-circle-defense.name', 'Dark Circle Defense'),
        t('products:anti-wrinkle-serum.name', 'Anti-Wrinkle Serum'),
      ],
      helpsWithDesc: t(
        'freeTrial:expert-maintenance-set-helps-with-desc',
        "Focuses on overall skin maintenance and provides the preventative tools to maintain your skin's health as you age. With targeted solutions built to combat tired looks, dark circles, and the appearance of fine lines - you'll look younger than ever, for longer. These combined with the fundamentals will work together to hydrate, firm, and deep-clean your skin."
      ),
    },
    {
      productSlug: 'fundamental-duo',
      desc: t(
        'freeTrial:fundamental-duo-set-desc',
        'Make sure to keep yourself in your prime. A targeted age recovery program that restores and maintains a manly, vigorous look.'
      ),
      helpsWith: [
        t(
          'freeTrial:repairing-sun-damage-and-evening-skin',
          'Repairing Sun Damage And Evening Skin Tone'
        ),
        t('freeTrial:undereye-repair-fundamental-duo', 'Undereye Repair'),
        t(
          'freeTrial:overnight-hydration-fundamental-duo',
          'Overnight Hydration'
        ),
      ],
      heroImage:
        'https://i.shgcdn.com/1961cf7f-ba93-46d9-a1eb-d099a2adfc43/-/format/auto/-/preview/3000x3000/-/quality/lighter/',
      kitIncludes: [
        t(
          'products:no-nonsense-charcoal-cleanser-fundamental-duo',
          'No-Nonsense Charcoal Cleanser'
        ),
        t(
          'products:ultra-hydrating-moisturizing-balm-fundamental-duo',
          'Ultra-Hydrating Moisturizing Balm'
        ),
      ],
      helpsWithDesc: t(
        'freeTrial:fundamental-duo-set-helps-with-desc',
        'Improve acne scarring and shaving irritation, while boosting skin health and deeply cleansing your pores. Moisturize, cleanse, and exfoliate to develop a routine that keeps you looking fresh and healthy.'
      ),
      concerns: [
        'oily-skin',
        'dry-skin',
        'overall-skin-health',
        'wrinkles',
        'sun-damage',
        'uneven-tone',
      ],
    },
    {
      productSlug: 'fundamental-skincare-trial-set-dry-skin',
      desc: t(
        'freeTrial:fundamental-duo-set-desc',
        'Make sure to keep yourself in your prime. A targeted age recovery program that restores and maintains a manly, vigorous look.'
      ),
      helpsWith: [
        t('freeTrial:reducing-excess-oil', 'Reducing excess oil'),
        t('freeTrial:fine-lines-and-wrinkles', 'Fine lines and wrinkles'),
        t('freeTrial:unclogging-pores', 'Unclogging pores'),
      ],
      heroImage:
        'https://i.shgcdn.com/1961cf7f-ba93-46d9-a1eb-d099a2adfc43/-/format/auto/-/preview/3000x3000/-/quality/lighter/',
      kitIncludes: [
        t(
          'products:no-nonsense-charcoal-cleanser-fundamental-duo',
          'No-Nonsense Charcoal Cleanser'
        ),
        t(
          'products:ultra-hydrating-moisturizing-balm-fundamental-duo',
          'Ultra-Hydrating Moisturizing Balm'
        ),
      ],
      helpsWithDesc: t(
        'freeTrial:fundamental-duo-set-helps-with-desc',
        'Improve acne scarring and shaving irritation, while boosting skin health and deeply cleansing your pores. Moisturize, cleanse, and exfoliate to develop a routine that keeps you looking fresh and healthy.'
      ),
    },
    {
      productSlug: 'fundamental-skincare-trial-set-oily-and-normal-skin',
      desc: t(
        'freeTrial:fundamental-duo-set-desc',
        'Make sure to keep yourself in your prime. A targeted age recovery program that restores and maintains a manly, vigorous look.'
      ),
      helpsWith: [
        t('freeTrial:reducing-excess-oil', 'Reducing excess oil'),
        t('freeTrial:fine-lines-and-wrinkles', 'Fine lines and wrinkles'),
        t('freeTrial:unclogging-pores', 'Unclogging pores'),
      ],
      heroImage:
        'https://i.shgcdn.com/1961cf7f-ba93-46d9-a1eb-d099a2adfc43/-/format/auto/-/preview/3000x3000/-/quality/lighter',
      kitIncludes: [
        t(
          'products:no-nonsense-charcoal-cleanser-fundamental-duo',
          'No-Nonsense Charcoal Cleanser'
        ),
        t(
          'products:ultra-hydrating-moisturizing-balm-fundamental-duo',
          'Ultra-Hydrating Moisturizing Balm'
        ),
      ],
      helpsWithDesc: t(
        'freeTrial:fundamental-duo-set-helps-with-desc',
        'Improve acne scarring and shaving irritation, while boosting skin health and deeply cleansing your pores. Moisturize, cleanse, and exfoliate to develop a routine that keeps you looking fresh and healthy.'
      ),
    },
    {
      productSlug: 'fundamental-duo-3-month-supply',
      desc: t(
        'freeTrial:fundamental-duo-set-desc',
        'Make sure to keep yourself in your prime. A targeted age recovery program that restores and maintains a manly, vigorous look.'
      ),
      helpsWith: [
        t(
          'freeTrial:repairing-sun-damage-and-evening-skin',
          'Repairing Sun Damage And Evening Skin Tone'
        ),
        t('freeTrial:undereye-repair-fundamental-duo', 'Undereye Repair'),
        t(
          'freeTrial:overnight-hydration-fundamental-duo',
          'Overnight Hydration'
        ),
      ],
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/Set-1Artboard-1.png?v=16418275402',
      kitIncludes: [
        t(
          'products:no-nonsense-charcoal-cleanser-quarterly-fundamental-duo',
          'No-Nonsense Charcoal Cleanser - x2'
        ),
        t(
          'products:ultra-hydrating-moisturizing-balm-quarterly-fundamental-duo',
          'Ultra-Hydrating Moisturizing Balm - x2'
        ),
      ],
      helpsWithDesc: t(
        'freeTrial:fundamental-duo-set-helps-with-desc',
        'Improve acne scarring and shaving irritation, while boosting skin health and deeply cleansing your pores. Moisturize, cleanse, and exfoliate to develop a routine that keeps you looking fresh and healthy.'
      ),
    },
    {
      productSlug: 'modern-bathroom-set',
      desc: t(
        'freeTrial:modern-bathroom-set-desc',
        "With freshly scented hair and body products, and a core 2-step skincare routine - you'll be feeling good, smelling good, and looking good."
      ),
      helpsWith: [
        t(
          'freeTrial:fundamental-skin-health',
          'Fundamental Skin Health & Cleanliness'
        ),
        t(
          'freeTrial:helps-with-body-odor',
          'Helps With Body Odor & Appearance Of Body Acne'
        ),
        t(
          'freeTrial:strengthens-repairs',
          'Strengthens, Repairs, And Maintains Hair Health'
        ),
      ],
      heroImage:
        'https://i.shgcdn.com/5a66fd59-f96b-4ba8-84a7-9b5a0c0a2f86/-/format/auto/-/preview/3000x3000/-/quality/lighter/',
      kitIncludes: [
        t(
          'products:ultra-hydrating-moisturizing-modern-bathroom',
          'Ultra-Hydrating Moisturizing Balm'
        ),
        t(
          'products:no-nonsense-charcoal-cleanser-modern-bathroom',
          'No-Nonsense Charcoal Cleanser'
        ),
        t(
          'products:clarifying-body-wash-modern-bathroom',
          'Clarifying Body Wash'
        ),
        t(
          'products:keratin-recovery-shampoo-modern-bathroom',
          'Keratin Recovery Shampoo'
        ),
        t(
          'products:keratin-recovery-conditioner-modern-bathroom',
          'Keratin Strengthening Conditioner'
        ),
      ],
      helpsWithDesc: t(
        'freeTrial:modern-bathroom-se-helps-with-desc',
        'Focuses on your key head-to-toe essentials. The moisturizer and cleanser duo helps enhance your already good looks, while removing all of the dirt, oil, and gunk build up in your pores and keeping your face intensely hydrated. Next, fend off bacne, body odor, and dirt build up across your new, fresh body wash. And finish with our hair-care duo. Built from Keratin, it targets damaaged hair and works to cleanse, hydrate, and repair.'
      ),
    },
    {
      productSlug: 'basic-man',
      desc: t(
        'freeTrial:must-haves-set-desc',
        "All you need, no more. A four item grouping of every man's basic must-haves built to maintain, refresh, and repair everywhere. Face, body, hair - covered."
      ),
      helpsWith: [
        t(
          'freeTrial:fundamental-skin-health',
          'Fundamental Skin Health & Cleanliness'
        ),
        t(
          'freeTrial:helps-with-body-odor',
          'Helps With Body Odor & Appearance Of Body Acne'
        ),
        t('freeTrial:strengthens-hair-follicles', 'Strengthens Hair Follicles'),
      ],
      heroImage:
        'https://i.shgcdn.com/340c8ba9-5a44-4c13-bdac-b1dfed64bb8e/-/format/auto/-/preview/3000x3000/-/quality/lighter/',
      kitIncludes: [
        t(
          'products:keratin-recovery-shampoo-basic-man',
          'Keratin Recovery Shampoo'
        ),
        t('products:clarifying-body-wash-basic-man', 'Clarifying Body Wash'),
        t(
          'products:no-nonsense-charcoal-cleanser-basic-man',
          'No-Nonsense Charcoal Cleanser'
        ),
        t(
          'products:ultra-hydrating-moisturizing-balm.name',
          'Ultra-Hydrating Moisturizing Balm'
        ),
      ],
      helpsWithDesc: t(
        'freeTrial:must-haves-set-helps-with-desc',
        "The basics because you've got to start somewhere. All you need, no more. A four item grouping of every man's basic must-haves built to maintain, refresh, and repair everywhere. Face, body, hair - covered."
      ),
    },
    //copy-of-up-top-management-set
    {
      productSlug: 'copy-of-up-top-management-set',
      desc: t(
        'freeTrial:copy-of-up-top-management-set-desc',
        'Keep it simple: two steps for your hair, two steps for your face. Keep hair follicles strong and healthy, intensely hydrate your face, and kick dark circles to the curb.'
      ),
      helpsWith: [
        t('freeTrial:dryness-and-dullness', 'Dryness And Dullness'),
        t('freeTrial:under-eye-bags', 'Under-eye bags'),
        t('freeTrial:strengthens-hair-follicles', 'Strengthens Hair Follicles'),
      ],
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/Up_Top_Management_2_Month_1.png?v=1599803731',
      kitIncludes: [
        t(
          'products:ultra-hydrating-moisturizing-balm.name',
          'Ultra-Hydrating Moisturizing Balm'
        ),
        t('products:dark-circle-defense.name', 'Dark Circle Defense'),
        t(
          'products:keratin-recovery-shampoo-basic-man',
          'Keratin Recovery Shampoo'
        ),
        t(
          'products:keratin-strengthening-conditioner.name',
          'Keratin Strengthening Conditioner'
        ),
      ],
      helpsWithDesc: t(
        'freeTrial:copy-of-up-top-management-set-helps-with-desc',
        'Dryness and dullness, Under-eye bags and Strengthening hair follicles.'
      ),
    },
    //youth-management-set
    {
      productSlug: 'youth-management-set',
      desc: t(
        'freeTrial:youth-management-set-set-desc',
        'For guys who want to repair sun damage and look in their prime. Cleans out your pores, hydrates and evens out your skin - all while combating under-eye bags and wrinkles.'
      ),
      helpsWith: [
        t('freeTrial:dehyration-and-dullness', 'Dehyration And Dullness'),
        t(
          'freeTrial:dark-circles-and-fine-lines',
          'Dark Circles And Fine Lines'
        ),
        t(
          'freeTrial:dirt-oil-and-pollution-build-up',
          'Dirt, Oil, And Pollution Build Up'
        ),
      ],
      heroImage:
        'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/YouthMgmt_1_1.png?v=1599809705',
      kitIncludes: [
        t('products:dark-circle-defense.name', 'Dark Circle Defense'),
        t('products:anti-wrinkle-serum.name', 'Anti-Wrinkle Serum'),
        t('products:touchless-face-mist.name', 'Touchless Face Mist'),
        t('products:charcoal-pore-strip.name', 'Charcoal Pore Strip'),
        t('products:repairing-face-mask.name', 'Repairing Face Mask'),
      ],
      helpsWithDesc: t(
        'freeTrial:youth-management-set-set-helps-with-desc',
        'Dehyration and dullness, Dark circles and fine lines and Dirt, oil, and pollution build up.'
      ),
    },
    {
      productSlug: 'anti-fatigue-essentials-set-spf',
      desc: t(
        'freeTrial:anti-fatigue-essentials-set-spf-desc',
        'Being active isn’t a choice, it’s a lifestyle. If you’re used to staying active, a good night’s sleep can be hard to get. This essential set is here to help your skin look more awake than you feel.'
      ),
      helpsWith: [
        t('freeTrial:sunburn-prevention', 'Sunburn prevention'),
        t('freeTrial:skin-hydration', 'Skin Hydration'),
        t('freeTrial:unclogging-pores', 'Unclogging pores'),
        t('freeTrial:dark-spots-reduction', 'Dark spots reduction'),
      ],
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/products/Anti-Fatigue_Swatch_1024x1024.png?v=1619557720',
      kitIncludesDesc: t(
        'freeTrial:what-you-need-to-look-refereshed-and-rest-every-day',
        'What you need to look refreshed and rested every day'
      ),
      kitIncludes: [
        t(
          'products:uv-defense-moisturizing-balm.name',
          'UV-Defense Moisturizing Balm'
        ),
        t('products:charcoal-cleanser.name', 'Charcoal Cleanser'),
        t('products:exfoliating-rub.name', 'Exfoliating Rub'),
        t('products:dark-circle-defense.name', 'Dark Circle Defense'),
      ],
      helpsWithDesc: t(
        'freeTrial:anti-fatigue-spf-helps-with-desc',
        ' Our curated ingredients help rid your pores of the work-week grime, remove dead skin cells, and keep your skin hydrated and protected. Plus your face will get an extra anti-fatigue boost to keep dark circles at bay.'
      ),
    },
    {
      productSlug: 'anti-fatigue-essentials-set-with-spf',
      desc: t(
        'freeTrial:anti-fatigue-essentials-set-spf-desc',
        'Being active isn’t a choice, it’s a lifestyle. If you’re used to staying active, a good night’s sleep can be hard to get. This essential set is here to help your skin look more awake than you feel.'
      ),
      helpsWith: [
        t('freeTrial:sunburn-prevention', 'Sunburn prevention'),
        t('freeTrial:skin-hydration', 'Skin Hydration'),
        t('freeTrial:unclogging-pores', 'Unclogging pores'),
        t('freeTrial:dark-spots-reduction', 'Dark spots reduction'),
      ],
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/products/Anti-Fatigue_Swatch_1024x1024.png?v=1619557720',
      kitIncludesDesc: t(
        'freeTrial:what-you-need-to-look-refereshed-and-rest-every-day',
        'What you need to look refreshed and rested every day'
      ),
      kitIncludes: [
        t(
          'products:uv-defense-moisturizing-balm.name',
          'UV-Defense Moisturizing Balm'
        ),
        t('products:charcoal-cleanser.name', 'Charcoal Cleanser'),
        t('products:exfoliating-rub.name', 'Exfoliating Rub'),
        t('products:dark-circle-defense.name', 'Dark Circle Defense'),
      ],
      helpsWithDesc: t(
        'freeTrial:anti-fatigue-spf-helps-with-desc',
        ' Our curated ingredients help rid your pores of the work-week grime, remove dead skin cells, and keep your skin hydrated and protected. Plus your face will get an extra anti-fatigue boost to keep dark circles at bay.'
      ),
    },
    {
      productSlug: 'age-management-set-with-spf',
      desc: t(
        'freeTrial:age-management-set-with-spf-desc',
        'Did you know that sun exposure can leave you looking tired and older?'
      ),
      helpsWith: [
        t(
          'freeTrial:reducing-redness-and-irritation',
          'Reducing redness and irritation'
        ),
        t('freeTrial:sunburn-prevention', 'Sunburn prevention'),
        t(
          'freeTrial:strengthening-the-skin-barrier',
          'Strengthening the skin barrier'
        ),
        t(
          'freeTrial:crows-feet-fine-line-premature-aging',
          "Reducing the appearance of crow's feet, fine lines, and the signs of premature aging"
        ),
      ],
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/Age-Management_Swatch.png?v=1619868591',
      kitIncludesDesc: t(
        'freeTrial:stop-looking-older-than-you-feel',
        'Stop looking older than you feel.'
      ),
      kitIncludes: [
        t(
          'products:uv-defense-moisturizing-balm.name',
          'UV-Defense Moisturizing Balm'
        ),
        t('products:dark-circle-defense.name', 'Dark Circle Defense'),
        t('products:anti-wrinkle-serum.name', 'Anti-Wrinkle Serum'),
      ],
      helpsWithDesc: t(
        'freeTrial:age-management-set-with-spf-helps-with-desc',
        ' Our targeted age-recovery routine is built to restore skin and reduce the signs of early aging such as wrinkles, under-eye bags, and skin damage from the sun. You don’t need to look 20 years younger, just like the best version of yourself.'
      ),
    },
    {
      productSlug: 'classic-maintenance-set-with-spf',
      desc: t(
        'freeTrial:classic-maintenance-set-with-spf-desc',
        'Skincare that works is simple. Our classic set is perfect for athletes, skincare noobies, and low-maintenance dudes.'
      ),
      helpsWith: [
        t('freeTrial:skin-damage-recovery', 'Skin damage recovery'),
        t(
          'freeTrial:relieving-shaving-irritation',
          'Relieving shaving irritation'
        ),
        t('freeTrial:reducing-excess-oil', 'Reducing excess oil'),
        t(
          'freeTrial:reducing-the-appearance-of-acne-scarring',
          'Reducing the appearance of acne scarring'
        ),
      ],
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/Classic-Maintenance_Swatch.png?v=1619721734',
      kitIncludesDesc: t(
        'freeTrial:the-fundamentals-of-healthy-skin',
        'The fundamentals of healthy skin.'
      ),
      kitIncludes: [
        t(
          'products:uv-defense-moisturizing-balm.name',
          'UV-Defense Moisturizing Balm'
        ),
        t('products:charcoal-cleanser.name', 'Charcoal Cleanser'),
        t('products:exfoliating-rub.name', 'Exfoliating Rub'),
      ],
      helpsWithDesc: t(
        'freeTrial:classic-maintenance-set-with-spf-helps-with-desc',
        " Expertly curated to provide the perfect balance of hydration, cleansing, and renewal to your complexion—it won't be long before the compliments start rolling in."
      ),
    },
    {
      productSlug: 'correction-trio-with-spf',
      desc: t(
        'freeTrial:correction-trio-with-spf-desc',
        'The essential routine for workaholics and dudes with dark circles that scream "sleep deprivation".'
      ),
      helpsWith: [
        t('freeTrial:removing-dead-skin', 'Removing dead skin'),
        t('freeTrial:blocking-pollution-impact', 'Blocking pollution impact'),
        t('freeTrial:dark-spots-reduction', 'Dark spots reduction'),
        t(
          'freeTrial:brightens-under-eye-dark-circles',
          'Brightens under-eye dark circles'
        ),
      ],
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/products/CorrectionTrio_Swatch.png?v=1619557886',
      kitIncludesDesc: t(
        'freeTrial:solutions-that-work-as-hard-as-you-do',
        'Solutions that work as hard as you do.'
      ),
      kitIncludes: [
        t(
          'products:uv-defense-moisturizing-balm.name',
          'UV-Defense Moisturizing Balm'
        ),
        t('products:exfoliating-rub.name', 'Exfoliating Rub'),
        t('products:dark-circle-defense.name', 'Dark Circle Defense'),
      ],
      helpsWithDesc: t(
        'freeTrial:correction-trio-with-spf-helps-with-desc',
        ' Whether you’re insomniac or a side hustler, you can brighten your under-eyes, reinvigorate dull skin, and reduce the effect of hyper-pigmentation on your complexion. Look prepped and ready to take on the world in no time.'
      ),
    },
    {
      productSlug: 'no-baggage-trio',
      desc: t(
        'products:no-baggage-trio.headline',
        'Get rid of excess oil, hydrate and brighten under eyes without the weight of a 12 step skincare routine.'
      ),
      helpsWith: [
        t('products:pdp-charcoal-clay-mask.benefit.1', 'Clears dirt build-up'),
        t('products:helpsWith.overall-skin-health', 'Overall skin health'),
        t('products:correction-trio-sets.benefit.2', 'Brightens dark circles'),
      ],
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/No_Baggage_Trio.png?v=1675172454',
      kitIncludes: [
        t(
          'products:no-nonsense-charcoal-cleanser-fundamental-duo',
          'No-Nonsense Charcoal Cleanser'
        ),
        t(
          'products:ultra-hydrating-moisturizing-balm-fundamental-duo',
          'Ultra-Hydrating Moisturizing Balm'
        ),
        t('products:dark-circle-defense.name', 'Dark Circle Defense'),
      ],
      helpsWithDesc: t(
        'products:no-baggage-trio.headline',
        'Get rid of excess oil, hydrate and brighten under eyes without the weight of a 12 step skincare routine.'
      ),
      concerns: [
        'oily-skin',
        'dry-skin',
        'overall-skin-health',
        'wrinkles',
        'uneven-tone',
      ],
    },
  ]

  const ret = data.find((a) => a.productSlug === slug)
  if (!ret) {
    Sentry.captureException(
      new Error(
        `No FreeTrialExtraProductData for free trial offer slug=${slug}`
      )
    )
  }
  return ret
}

export const getRedoText = (
  t: TFunction,
  config: TrialConfig,
  recurringPrice?: string,
  renewalDiscountPrice?: string,
  totalPercentOff?: number,
  recurringMonthlyInterval?: number
): TextProps[] => {
  const price = config.renewalDiscount ? renewalDiscountPrice : recurringPrice
  const initialInterval = config.initialInterval || 30
  return [
    ...(config.renewalDiscount
      ? [
          {
            text: t(
              'freeTrial:if-you-choose-not-to-cancel',
              'If you choose not to cancel, we’ll ship and bill you a'
            ),
          },
          {
            text: t(
              'freeTrial:new-larger-box-every-two-months',
              'new larger box every 2 months.'
            ),
            isBold: true,
          },
        ]
      : [
          {
            text: t(
              'freeTrial:if-you-choose-not-to-cancel-in-30-days',
              'If you choose not to cancel, in {{ interval }} days, we’ll ship you a',
              { interval: initialInterval }
            ),
          },
          {
            text: t(
              'freeTrial:new-larger-subscription-box',
              'new, larger subscription box'
            ),
            isBold: true,
          },
          {
            text: t(
              `freeTrial:discount-to-retail-prices-3`,
              'for {{price}} (plus shipping + taxes), a {{totalPercentOff}}% discount to retail price! Your box will be shipped and billed',
              { price, totalPercentOff }
            ),
          },
          {
            text: t(
              'freeTrial:every-interval-month-dot',
              'every {{recurringMonthlyInterval}} months.',
              { recurringMonthlyInterval }
            ),
            isBold: true,
          },
        ]),
  ]
}

export const getNewCopyRedoText = (t: TFunction, totalPercentOff: number) => [
  {
    text: t('freeTrial:your-first', 'Your first '),
  },
  {
    text: t('freeTrial:full-size-box', 'FULL size box '),
    isBold: true,
  },
  {
    text: t(
      'freeTrial:will-ship-30-days-after-you-start-your-free-trial',
      'will ship 30 days after you start your FREE trial. Remember, you get'
    ),
  },
  {
    text: t(
      'freeTrial:percent-off-the-one-off-purchase-price',
      '{{totalPercentOff}}% off the one-off purchase price',
      { totalPercentOff }
    ),
    isBold: true,
  },
  {
    text: t('feeTrial:as-a-subscriber', 'as a subscriber!'),
  },
]
